import React from 'react';
import css from './Switcher.module.css';
import PropTypes from 'prop-types';

const Switcher = ({ activeKey, setActiveKey, onChange, size, items, fullWidth }) => {
  const handleItemClick = key => {
    setActiveKey(key);
    if (onChange) {
      onChange(key);
    }
  };

  return (
    <div className={`${css.switcher} ${css[size]} ${fullWidth ? css.switcherFullWidth : ''}`}>
      {items.map(item => (
        <div
          className={`
            ${css.switcherButton}
            ${css[size]}
            ${activeKey === item.key ? css.active : ''}
            ${fullWidth ? css.switcherButtonFullWidth : ''}
          `}
          key={item.label}
          onClick={() => handleItemClick(item.key)}
        >
          {item.label}
          {item.count > -1 && (
            <div className={css.additional}>{item.count}</div>
          )}
        </div>
      ))}
    </div>
  );
};

const { func, oneOf, arrayOf, object, bool, string } = PropTypes;

Switcher.defaultProps = {
  activeKey: null,
  setActiveKey: () => {},
  onChange: () => {},
  size: 'md',
  items: null,
  fullWidth: false,
};

Switcher.propTypes = {
  activeKey: string,
  setActiveKey: func,
  onChange: func,
  size: oneOf(['xs', 'sm', 'md', 'lg']),
  items: arrayOf(object),
  fullWidth: bool,
};

export default Switcher;
