import { useEffect, useState } from 'react';
import css from './ChatContent.module.css'
import paperClipIcon from '../../../../../../assets/icons/paper-clip.svg';
import paperAirplane from '../../../../../../assets/icons/paper-airplane.svg';
import { Button } from '../../../../../../components/New/Button/Button';
import { isEmpty } from 'lodash';
import axiosInstance from '../../../../../../axios';
import { formatChatCreatedDate } from '../../../../../../util/dates';
import { useSocket } from '../../../../../../hooks/useSocket';

export const ChatContent = ({
  applicants,
  onClickOpenConfirmDialog,
  onClickOpenConfirmOfferDialog,
}) => {
  const socket = useSocket();

  const [selected, setSelected] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatroom, setChatroom] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [height, setHeight] = useState(40);

  const handleInputChange = (e) => {
    setHeight(isEmpty(e.target.value) ? 40 : e.target.scrollHeight);
    setNewMessage(e.target.value);
  }

  const fetchChatroomMessages = async (chatroomId) => {
    try {
      const response = await axiosInstance.get(`/chatroom/detail/${chatroomId}`);
      const { data } = response.data;

      await axiosInstance.post(`/chatroom/read/all/${data.id}`, {
        userId: data.customerId,
      });

      setChatroom(data);

      const orderMessages = data.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setChatMessages(orderMessages);
    } catch (error) {
      console.error(error);
    }
  }

  const sendNewMessage = async () => {
    try {
      if (isEmpty(newMessage)) {
        return;
      }

      setChatMessages([...chatMessages, {
        message: newMessage,
        role: 'customer',
        userId: chatroom.customerId,
        createdAt: new Date(),
      }]);
      setNewMessage('');

      // new logic
      await axiosInstance.post('/messages/create', {
        data: {
          chatroomId: selected.chatroomId,
          role: 'customer',
          userId: chatroom.customerId,
          message: newMessage,
        }
      });

      socket.emit('newMessage', newMessage);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (selected) {
      fetchChatroomMessages(selected.chatroomId);
    }

    if (socket && selected) {
      socket.on('fetchUnreadMessages', (msg) => {
        fetchChatroomMessages(selected.chatroomId);
      });
    }

    return () => {
      socket.off('fetchUnreadMessages');
    };
  }, [selected, socket]);

  return (
    <>
      {applicants.length === 0 ? (
        <div className={css.noApplicants}>No applicants to display</div>
      ) : (
        <div className={css.root}>
          <div className={css.left}>
            {applicants.map((applicant) => (
              <div className={`${css.card} ${selected?.id === applicant.id && css.cardSelected}`} onClick={() => setSelected(applicant)}>
                <img src={applicant.creator.avatar} alt="Creator" className={css.avatar} />
                <div className={css.cardContent}>
                  <div>{applicant.creator.displayName}</div>
                </div>
              </div>
            ))}
          </div>
          <div className={css.right}>
            {selected && (
              <div className={css.chat}>
                <div className={css.chatBanner}>
                  <div className={css.chatBannerContent}>
                    <img src={selected.creator.avatar} alt="Creator" className={css.avatar} />
                    <div>{selected.creator.displayName}</div>
                  </div>
                </div>
                <div className={css.chatContainer}>
                  {chatMessages.map((message) => (
                    <div className={message.role === 'provider' ? css.bubbleLeft : css.bubbleRight}>
                      <div className={css.bubble}>
                        <div className={css.bubbleCreatedAt}>{formatChatCreatedDate(message.createdAt)}</div>
                        {message.message}
                      </div>
                    </div>
                  ))}
                </div>
                <div className={css.chatInputContainer}>
                  <button className={css.attachButton}><img src={paperClipIcon} className={css.chatButtonIcon}/></button>
                  <textarea placeholder='Message' value={newMessage} className={css.messageInput} onChange={handleInputChange} style={{ height: `${height}px`, maxHeight: '100px' }} />
                  <button className={css.sendButton} onClick={sendNewMessage}><img src={paperAirplane} className={css.chatButtonIcon}/></button>
                </div>
              </div>
            )}
          </div>
          <div className={css.banner}>
            {chatroom && (
              <div className={css.bannerContainer}>
                <img src={chatroom.provider.avatar} className={css.providerAvatar} />
                <div className={css.providerName}>{chatroom.provider.displayName}</div>
                <div>{chatroom.provider.activity}</div>
                <div className={css.buttonContainer}>
                  {selected.status === 'interview' && (
                    <>
                      <Button color='black' fullWidth onClick={() => onClickOpenConfirmOfferDialog(selected)}>Offer Job</Button>
                      <Button color='gray' fullWidth onClick={() => onClickOpenConfirmDialog(selected, 'rejected')}>Reject</Button>
                    </>
                  )}
                  {selected.status === 'offered' && (
                    <>
                      <Button color='success' fullWidth onClick={() => {}}>Job offered</Button>
                      <Button color='gray' fullWidth onClick={() => onClickOpenConfirmDialog(selected, 'rejected')}>Reject</Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
