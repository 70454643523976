import css from './AppliedTable.module.css'
import instagramIcon from '../../../../../../assets/icons/instagramLogo.svg';
import tiktokIcon from '../../../../../../assets/icons/tiktokLogo.svg';
import { ArrowPagination } from '../../../../../../components/PaginationCollection/ArrowPagination/ArrowPagination';
import { useState } from 'react';
import { camelToLabel, formatDateString } from '../../../../../../util/string';
import { Button } from '../../../../../../components/New/Button/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const tableHeaders = [
  { key: 'creator', label: 'Creators' },
  { key: 'socialMedia', label: 'Socials' },
  { key: 'resume', label: 'Resume' },
  { key: 'portfolio', label: 'Portfolio' },
  { key: 'usageDate', label: 'Date' },
  { key: 'actions', label: 'Actions' }
];

export default function AppliedTable ({
  applicants,
  onClickOpenConfirmDialog,
  onClickShortlist,
}) {
  const history = useHistory();
  const headers = tableHeaders;
  const iconMap = {
    tiktok: tiktokIcon,
    instagram: instagramIcon,
  }

  const itemsPerPage = 20;
  const totalPages = Math.ceil(applicants.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (newCurrentPage) => {
    setCurrentPage(newCurrentPage);
  }

  // Calculate the applicants to display for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentApplicants = applicants.slice(startIndex, endIndex);
  console.log(currentApplicants)

  return (
    <div>
      <table className={css.table}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.key}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentApplicants.map((applicant, index) => (
            <tr key={`tr-${applicant.id}-${index}`}>
              <td>
                <div className={css.creatorContainer} onClick={() => history.push(`/creator/${applicant.creator.id}`)}>
                  <img src={applicant.creator.avatar} alt="Creator" className={css.creatorAvatar} />
                  <div>{applicant.creator.displayName}</div>
                </div>
              </td>
              <td>
                <div className={css.socialMediaContainer}>
                  {applicant.creator.instagram && <a href={applicant.creator.instagram} target='_blank'>
                    <img src={iconMap.instagram} alt="Social Media" className={css.socialMediaIcon} />
                  </a>}
                  {applicant.creator.tiktok && <a href={applicant.creator.tiktok} target='_blank'>
                    <img src={iconMap.tiktok} alt="Social Media" className={css.socialMediaIcon} />
                  </a>}
                </div>
              </td>
              <td>
                {applicant.documents.find((doc) => doc.name === 'resume') ? (
                  <a href={applicant.documents.find((doc) => doc.name === 'resume')?.link} target='_blank'>Open Resume</a>
                ) : (
                  <span>Not available</span>
                )}
              </td>
              <td>
                {applicant.documents.find((doc) => doc.name === 'portfolio') ? (
                  <a href={applicant.documents.find((doc) => doc.name === 'portfolio')?.link} target='_blank'>Open Portfolio</a>
                ) : (
                  <span>Not available</span>
                )}
              </td>
              <td>
                <div>{formatDateString(applicant.createdAt)}</div>
              </td>
              <td>
                <div className={css.actionContainer}>
                  <div className={css.actionContent}>
                    {applicant.status === 'applied' ? (
                      <div className={css.actionButtonContainer}>
                        <Button size='small' color='gray' onClick={() => onClickShortlist(applicant.id)}>Shortlist</Button>
                        <Button size='small' color='gray' onClick={() => onClickOpenConfirmDialog(applicant, 'rejected')}>Reject</Button>
                      </div>
                    ) : (
                      <div className={css.applicationStatusText}>
                        {camelToLabel(applicant.status)}
                      </div>
                    )}
                  </div>
                </div>
              </td>
            </tr>
          ))}
          {/* pagination */}
          {currentApplicants.length > 0 ? (
            <tr>
              <td colSpan={headers.length}>
                <ArrowPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={newCurrentPage => onPageChange(newCurrentPage)}
                />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={headers.length}>
                <div className={css.noApplicantsText}>No applicants to display</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
