import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  Switcher,
} from '../../../../components';
import css from './CraetorViewJobPage.module.css';
import TopbarContainer from '../../../TopbarContainer/TopbarContainer';
import { Button } from '../../../../components/New/Button/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import axiosInstance from '../../../../axios';
import { JobCard } from '../../../../components/JobCard/JobCard';
import emptyIcon from '../../../../assets/icons/empty.svg';
import whiteBriefcaseIcon from '../../../../assets/icons/white_briefcase.svg';
import whiteBagIcon from '../../../../assets/icons/white_bag.svg';
import { JobAppliedCard } from '../../../../components/JobAppliedCard/JobAppliedCard';
import Loading from '../../../../components/Loading/Loading';

const switcherItems = [
  { label: 'Jobs', key: 'jobs' },
  { label: 'Applied', key: 'applied' },
];

const CreatorViewJobPage = ({ currentUser }) => {
  const history = useHistory();
  const userId = currentUser?.id?.uuid;

  const [loading, setLoading] = useState(false);
  const [switcherKey, setSwitcherKey] = useState(switcherItems[0].key);
  const [jobs, setJobs] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  const fetchAppliedJobs = async () => {
    try {
      const response = await axiosInstance.get(`/job/creatior/list/applied/${userId}`);
      const { data } = response.data;
      setAppliedJobs(data);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchAllJobs = async () => {
    try {
      const response = await axiosInstance.get('/job/creator/list/all');
      const { data } = response.data;
      setJobs(data);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUserJobs = async () => {
    try {
      setLoading(true);
      Promise.allSettled([
        fetchAllJobs(),
        fetchAppliedJobs(),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUserJobs();
  }, []);

  return (
    <Page
      title={"Job Page"}
      scrollingDisabled={false}
    >
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={'CreatorJobPage'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab={'CreatorJobPage'}
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.headerContainer}>
            <div className={css.headerTitle}>Jobs</div>
          </div>
          <div className={css.contentContainer}>
            <div>
              <Switcher
                activeKey={switcherKey}
                setActiveKey={setSwitcherKey}
                onChange={() => {}}
                items={switcherItems}
                fullWidth
              />
            </div>
            <div className={css.fullHeight}>
              {loading ? (
                <Loading type='animation' />
              ) : (
                <>
                  {switcherKey === 'applied' && (
                    <>
                      {appliedJobs.length === 0 ? (
                        <div className={css.noJobContainer}>
                          <img src={emptyIcon} alt="empty icon" />
                          <div className={css.noJobLabel}>Start applying for Jobs</div>
                          <div className={css.noJobText}>You haven't applied for any jobs yet. Check out available positions and start your career journey by applying today!</div>
                          <Button size='medium' onClick={() => setSwitcherKey('jobs')}><img src={whiteBriefcaseIcon} alt="add icon" />Jobs</Button>
                        </div>
                      ) : (
                        <div className={css.jobContainer}>
                          {appliedJobs.map((job, index) => (
                            <JobAppliedCard job={job} key={index} showStatus />
                          ))}
                        </div>
                      )}
                    </>
                  )}
                  {switcherKey === 'jobs' && (
                    <>
                      {jobs.length === 0 ? (
                        <div className={css.noJobContainer}>
                          <img src={emptyIcon} alt="empty icon" />
                          <div className={css.noJobLabel}>No active Job Listings</div>
                          <div className={css.noJobText}>Currently, there are no active job listings from employers. In the meantime, feel free to apply for ongoing campaigns!</div>
                          <Button size='medium' onClick={() => history.push('/campaigns')}><img src={whiteBagIcon} alt="add icon" />Campaign Marketplace</Button>
                        </div>
                      ) : (
                        <div className={css.jobContainer}>
                          {jobs.map((job, index) => (
                            <JobCard job={job} key={index} />
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  )
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
};

export default compose(connect(mapStateToProps))(CreatorViewJobPage);

