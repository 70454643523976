import { BlackButton, SoftBlackButton, WhiteButton } from "../../Button/Button"
import ModalV2Simple from "../../ModalV2/ModalV2Simple"
import css from "./ConfirmationDialog.module.css";

export const ConfirmationDialog = ({
  open,
  onClose,
  title,
  width = 500,
  message,
  onConfirm,
}) => {
  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      title={title}
      width={width}
    >
      <div className={css.messageText}>{message}</div>
      <div className={css.btnContainer}>
        <SoftBlackButton isOutlined onClick={onClose}>Close</SoftBlackButton>
        <BlackButton onClick={onConfirm}>Confirm</BlackButton>
      </div>
    </ModalV2Simple>
  )
}
