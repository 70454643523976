import { useEffect, useState } from 'react';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../../../components';
import TopbarContainer from '../../../TopbarContainer/TopbarContainer';
import css from './ApplyJobPage.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axiosInstance from '../../../../axios';
import Loading from '../../../../components/Loading/Loading';
import checkIcon from '../../../../assets/icons/check-circle-black.svg';
import globeIcon from '../../../../assets/icons/globe.svg';
import instagramIcon from '../../../../assets/icons/instagramColor.svg';
import tiktokIcon from '../../../../assets/icons/tiktokColor.svg';
import { Button } from '../../../../components/New/Button/Button';
import briefCase from '../../../../assets/icons/briefcase.svg';
import calendarIcon from '../../../../assets/icons/empty_calendar.svg';
import bellIcon from '../../../../assets/icons/bell.svg';
import { camelToLabel } from '../../../../util/string';
import { ApplyJobDialog } from '../../../../components/Dialogs/JobApplyDialog/JobApplyDialog';
import ModalV2Simple from '../../../../components/ModalV2/ModalV2Simple';

const ApplyJobPage = ({ currentUser }) => {
  const { id } = useParams();
  const history = useHistory();
  const userId = currentUser?.id?.uuid;

  const [job, setJob] = useState(null);
  const [isApplied, setIsApplied] = useState(null);
  const [loading, setLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const [openApplyDialog, setOpenApplyDialog] = useState(false);

  const fetchJob = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/job/creator/apply/${id}`);
      const { error, message, data } = response.data;

      const isApplied = data.jobApplicants.filter((applicant) => applicant.creator.id === userId).length > 0 ? true : false;
      setIsApplied(isApplied);

      setJob(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJob();
  }, [id]);

  const dateOptions = { day: 'numeric', month: 'long' };
  const dateFormatter = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString('en-GB', dateOptions);
  }

  return (
    <Page title={`Apply ${job?.title} Page`} scrollingDisabled={false}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={'JobPage'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab={'JobPage'}
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          {loading && <Loading type='animation' />}
          {!loading && job != null && (
            <div className={css.content}>
              <Breadcrumb
                items={[
                  {
                    label: 'Jobs',
                    onClick: () => history.push('/job/creator'),
                  },
                  {
                    label: `${job.title}`,
                  },
                ]}
              />
              <div className={css.baseContainer}>
                <div className={css.left}>
                  <div className={css.cardContainer}>
                    <div className={css.flex16}>
                      <div>
                        <div className={css.offerText}>
                          {job.currency} {job.budgetRangeA} {job.budgetRangeB && ` - ${job.budgetRangeB}`}
                        </div>
                        <div className={css.contentText}>Budget</div>
                      </div>
                      <div className={css.flex16}>
                        <div className={css.campaignInfoItemContainter}>
                          <div className={css.iconButton}><img src={briefCase} className={css.imageIcon} alt='workplace icon' /></div>
                          <div>
                            <div className={css.menuSubtitleText}>Workplace</div>
                            <div className={css.contentText}>{job.workplace}</div>
                          </div>
                        </div>
                        <div className={css.campaignInfoItemContainter}>
                          <div className={css.iconButton}><img src={calendarIcon} alt='calendar icon' /></div>
                          <div>
                            <div className={css.menuSubtitleText}>Contract duration</div>
                            <div className={css.contentText}>{job.duration} {camelToLabel(job.durationType)}</div>
                          </div>
                        </div>
                        <div className={css.campaignInfoItemContainter}>
                          <div className={css.iconButton}><img src={bellIcon} alt='bell icon' /></div>
                          <div>
                            <div className={css.menuSubtitleText}>Application deadline</div>
                            <div className={css.contentText}>{dateFormatter(job.applicationEndDate)}</div>
                          </div>
                        </div>
                        <div className={css.campaignInfoItemContainter}>
                          <div className={css.iconButton}><img src={globeIcon} alt='globe icon' /></div>
                          <div>
                            <div className={css.menuSubtitleText}>Location</div>
                            <div className={css.contentText}>{job.location}</div>
                          </div>
                        </div>
                      </div>
                      {isApplied && !loading ? (
                        <Button color='gray' size='medium'>
                          <img src={checkIcon} alt="applied icon" />
                          Applied
                        </Button>
                      ) : (
                        <Button onClick={() => setOpenApplyDialog(true)} disabled={applyLoading} size='medium'>
                          Apply {applyLoading && <div><Loading /></div>}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className={css.right}>
                  <div className={css.cardContainer}>
                    <div className={css.flex16}>
                      <div>
                        <div className={css.headerContainer}>
                          <div>
                            <div className={css.campaignNameText}>{job.title}</div>
                            <div className={css.contentText}>{job.location}</div>
                          </div>
                          <div>
                            <div className={css.contentText}>{dateFormatter(job.updatedAt)}</div>
                          </div>
                        </div>
                      </div>
                      <div className={css.flex24}>
                        <div className={css.contentContainer}>
                          <div className={css.subtitleText}>Job Description</div>
                          <div className={css.contentText}>{job.description}</div>
                        </div>
                        {/* <div className={css.contentContainer}>
                          <div className={css.subtitleText}>Deliverables</div>
                        </div> */}
                      </div>
                      {/* <div>
                        <div className={css.subtitleText}>Tags</div>
                        <div className={css.tagContainer}>
                          {job.creatorNiche.map((tag, index) => (
                            <div className={css.tagItem}>{tag}</div>
                          ))}
                          {job.creatorLanguage.map((tag, index) => (
                            <div className={css.tagItem}>{tag}</div>
                          ))}
                          {job.creatorGender.map((tag, index) => (
                            <div className={css.tagItem}>{tag}</div>
                          ))}
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className={css.cardContainer}>
                    <div className={css.flex16}>
                      <div className={css.clientProfileContainer}>
                        <div className={css.avatarImageContainer}>
                          <img src={job.client.avatar} alt="client profile image" />
                        </div>
                        <div>
                          <div className={css.menuSubtitleText}>{job.client.displayName}</div>
                          <div>{camelToLabel(job.client.userType)}</div>
                        </div>
                      </div>
                      <div className={css.contentText}>{job.client.bio}</div>
                      <div className={css.socialMediaGrid}>
                        {job.client.instagram && (
                          <a href={job.client.instagram} target='_blank' className={css.socialMediaItem}>
                            <div><img src={instagramIcon} alt='instagram icon'/></div>
                          </a>
                        )}
                        {job.client.tiktok && (
                          <a href={job.client.tiktok} target='_blank' className={css.socialMediaItem}>
                            <div><img src={tiktokIcon} alt='tiktok icon'/></div>
                          </a>
                        )}
                        {job.client.website && (
                          <a href={job.client.website} target='_blank' className={css.socialMediaItem}>
                            <div><img src={globeIcon} alt='website icon'/></div>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ApplyJobDialog
            open={openApplyDialog}
            onClose={() => setOpenApplyDialog(false)}
            job={job}
            userId={userId}
            onApply={() => setIsApplied(true)}
          />
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

export default compose(connect(mapStateToProps))(ApplyJobPage);
