import css from './AddMoreCreators.module.css';
import add_circle_icon from '../../../../../../assets/icons/add-circle.svg';

const AddMoreCreators = ({onClick}) => {
  return (
    <div className={css.container} onClick={onClick}>
      <div className={css.title}>
        <img src={add_circle_icon} className={css.addCircleIcon} />
        Invite Creators
      </div>
      <div className={css.description}>
        Offer creators a job invitation.
      </div>
    </div>
  )
}

export default AddMoreCreators;
