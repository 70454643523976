// Convert kebab-case to camelCase: my-page-asset > myPageAsset
export const camelize = s => s.replace(/-(.)/g, l => l[1].toUpperCase());

export const formatDateString = (dateString) => {
  const postedDate = new Date(dateString).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long'
  });
  return postedDate;
}

export const camelToLabel = (camelCaseStr) => {
  if (!camelCaseStr) {
    return '';
  }

  // Split the camelCase string into words
  const words = camelCaseStr.match(/[A-Z]?[a-z]+/g);

  // Capitalize the first letter of each word and join them with spaces
  const properCaseStr = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return properCaseStr;
}
