import css from './TextField.module.css';

export const TextField = ({
  id = null,
  name = null,
  value = null,
  onChange = null,
  onBlur = null,
  placeholder = null,
  type = 'text',
  errorMessage = null,
  disabled = false,
  className = null,
}) => {
  return (
    <div>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChange ? onChange(e) : null}
        onBlur={(e) => onBlur ? onBlur(e) : null}
        placeholder={placeholder}
        className={`${css.baseContainer} ${css.baseFont} ${className}`}
        disabled={disabled}
      />
      <div className={css.errorMessage}>{errorMessage}</div>
    </div>
  )
}
