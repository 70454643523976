import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../../../components';
import css from './EditJobPage.module.css';
import TopbarContainer from '../../../TopbarContainer/TopbarContainer';
import { Button } from '../../../../components/New/Button/Button';
import { TextField } from '../../../../components/New/TextField/TextField';
import { TextAreaField } from '../../../../components/New/TextAreaField/TextAreaField';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axiosInstance from '../../../../axios';
import toast from 'react-hot-toast';
import LoadingOverlayScreen from '../../../../components/LoadingOverlayScreen/LoadingOverlayScreen';
import ProgressBar from '../../../Campaigns/components/ProgressBar/ProgressBar';
import * as Yup from 'yup';

const EditJobPage = ({ currentUser }) => {
  const history = useHistory();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchJobDetails = async () => {
    try {
      const response = await axiosInstance.get(`/job/detail/${id}`);
      const { error, message, data } = response.data;
      if (error) {
        console.error(message);
        return;
      }

      let otherDocuments = data.documents.filter((item) => !['resume', 'portfolio'].includes(item));

      basicFormik.resetForm({
        values: {
          title: data.title,
          description: data.description,
          documents: data.documents ?? [],
          otherDocuments: otherDocuments,
        }
      });

      logisticFormik.resetForm({
        values: {
          applicationEndDate: data.applicationEndDate ?? '',
          type: data.type ?? '',
          duration: data.duration ?? '',
          durationType: data.durationType ?? '',
          currency: data.currency ?? '',
          budgetType: data.budgetType ?? '',
          budgetRangeA: data.budgetRangeA ?? 0,
          budgetRangeB: data.budgetRangeB ?? 0,
          workplace: data.workplace ?? '',
          location: data.location ?? '',
          talentLocation: data.talentLocation ?? '',
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchJobDetails();
  }, [id]);

  const basicFormik = useFormik({
    initialValues: {
      title: '',
      description: '',
      documents: [],
      otherDocuments: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
      documents: Yup.array(),
    }),
    onSubmit: async (values) => {
      await handleUpdateBasicFormik();
    }
  });

  const logisticFormik = useFormik({
    initialValues: {
      applicationEndDate: '',
      type: '',
      duration: '',
      durationType: '',
      currency: '',
      budgetType: '',
      budgetRangeA: '',
      budgetRangeB: '',
      workplace: '',
      location: '',
      talentLocation: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      applicationEndDate: Yup.string().required('Application end date is required'),
      type: Yup.string().required('Employment type is required'),
      currency: Yup.string().required('Currency is required'),
      budgetType: Yup.string().required('Budget type is required'),
      budgetRangeA: Yup.number().required('Budget range is required'),
      workplace: Yup.string().required('Workplace is required'),
      location: Yup.string().required('Location is required'),
      talentLocation: Yup.string().required('Talent location is required'),
    }),
    onSubmit: async (values) => {
      await handleUpdateLogisticFormik();
    }
  });

  const requiredDocuments = [
    { value: 'resume', label: 'Resume' },
    { value: 'portfolio', label: 'Portfolio' },
    // { value: 'other', label: 'Other' },
  ]

  const handleClickPlatform = async (document) => {
    const { documents } = basicFormik.values;
    let newDocuments = [];
    if (documents.includes(document)) {
      newDocuments = documents.filter(item => item !== document);
    } else {
      newDocuments = [...documents, document];
    }
    basicFormik.setFieldValue('documents', newDocuments);
  }

  const onClickNotRequired = async () => {
    basicFormik.setFieldValue('documents', []);
  }


  const hasOtherDocuments = basicFormik.values.documents.filter(item => !['resume', 'portfolio'].includes(item)).length > 0;
  console.log(hasOtherDocuments);
  const onClickOther = async () => {
    if (hasOtherDocuments) {
      basicFormik.setFieldValue('documents', basicFormik.values.documents.filter(item => ['resume', 'portfolio'].includes(item)));
      basicFormik.setFieldValue('otherDocuments', '');
    } else {
      basicFormik.setFieldValue('documents', [...basicFormik.values.documents, 'other']);
    }
  }

  const handleUpdateBasicFormik = async () => {
    try {
      setLoading(true);
      let documents = basicFormik.values.documents;
      documents = documents.filter(item => ['resume', 'portfolio'].includes(item)); //insert all required documents
      if (hasOtherDocuments) {
        documents = [...documents, basicFormik.values.otherDocuments];
      }

      const response = await axiosInstance.post(`/job/update/${id}`, {
        title: basicFormik.values.title,
        description: basicFormik.values.description,
        documents: documents,
      });
      const { error, message } = response.data;
      if (error) {
        toast.error(message);
        return;
      }

      setPage(2);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleUpdateLogisticFormik = async () => {
    try {
      setLoading(true);

      const response = await axiosInstance.post(`/job/update/${id}`, {
        applicationEndDate: logisticFormik.values.applicationEndDate ? new Date(logisticFormik.values.applicationEndDate).toISOString() : undefined,
        type: logisticFormik.values.type,
        duration: logisticFormik.values.duration.toString() ?? undefined,
        durationType: logisticFormik.values.durationType ?? undefined,
        currency: logisticFormik.values.currency,
        budgetType: logisticFormik.values.budgetType,
        budgetRangeA: logisticFormik.values.budgetRangeA,
        budgetRangeB: logisticFormik.values.budgetRangeB || undefined,
        workplace: logisticFormik.values.workplace,
        location: logisticFormik.values.location,
        talentLocation: logisticFormik.values.talentLocation,
        status: 'active',
      });
      const { error, message } = response.data;
      if (error) {
        toast.error(message);
        return;
      }

      history.push(`/job/client/detail/${id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleClickBack = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  return (
    <Page
      title={"Create Job Page"}
      scrollingDisabled={false}
    >
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={'ClientJobPage'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab={'ClientJobPage'}
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.container}>
            <div className={css.left}>
              {page === 1 && (
                <>
                  <div className={css.headerText}>Create A New Job</div>
                  <div className={css.subheaderText}>Clearly define the role and responsibilities</div>
                  <div className={css.formContainer}>
                    <div>
                      <div className={css.label}>Job Title</div>
                      <TextField name={'title'} value={basicFormik.values.title} onChange={basicFormik.handleChange} onBlur={basicFormik.handleBlur} placeholder={"Input job title"} errorMessage={basicFormik.errors.title} />
                    </div>
                    <div>
                      <div className={css.label}>Job Description</div>
                      <TextAreaField
                        name={'description'}
                        value={basicFormik.values.description}
                        onChange={basicFormik.handleChange}
                        onBlur={basicFormik.handleBlur}
                        placeholder={"Describe the role"}
                        errorMessage={basicFormik.errors.description}
                        height={"550px"}
                      />
                    </div>
                    <div>
                      <div className={css.label}>Select the required documents for applicants to submit</div>
                      <div className={css.documentInputContainer}>
                        {requiredDocuments.map((item, index) => (
                          <div
                            key={index}
                            className={classNames(css.selectContainer, basicFormik.values.documents.includes(item.value) && css.selectSelected)}
                            onClick={() => {item.value === 'not-required' ? onClickNotRequired() : handleClickPlatform(item.value)}}
                          >
                            {item.label}
                          </div>
                        ))}
                        <div
                          className={classNames(css.selectContainer, hasOtherDocuments && css.selectSelected)}
                          onClick={onClickOther}
                        >
                          Other
                        </div>
                        <div
                          className={`${css.selectContainer} ${css.selectNotRequired} ${basicFormik.values.documents.length === 0 && css.selectSelected}`}
                          onClick={onClickNotRequired}
                        >
                          Not Required
                        </div>
                      </div>
                      <div className={css.errorMessage}>{basicFormik.errors.documents}</div>
                      {hasOtherDocuments && (
                        <TextField placeholder={"Other platform"} name={'otherDocuments'} value={basicFormik.values.otherDocuments} onChange={basicFormik.handleChange}/>
                      )}
                    </div>
                  </div>
                </>
              )}
              {page === 2 && (
                <>
                  <div className={css.headerText}>Logistics</div>
                  <div className={css.subheaderText}>Specify key information for your application</div>
                  <div className={css.formContainer}>
                    <div>
                      <div className={css.label}>Application deadline</div>
                      <input type="date" name='applicationEndDate' className={css.inputText} value={logisticFormik.values.applicationEndDate} onChange={logisticFormik.handleChange}/>
                      <div className={css.errorMessage}>{logisticFormik.errors.applicationEndDate}</div>
                    </div>
                    <div>
                      <div className={css.label}>Employment Type</div>
                      <select className={css.inputSelect} name='type' value={logisticFormik.values.type} onChange={logisticFormik.handleChange}>
                        <option value="">Select</option>
                        <option value="fullTime">Full Time</option>
                        <option value="partTime">Part Time</option>
                        <option value="contract">Contract</option>
                      </select>
                      <div className={css.errorMessage}>{logisticFormik.errors.type}</div>
                    </div>
                    {logisticFormik.values.type === 'contract' && (
                      <div>
                        <div className={css.label}>Contract Duration</div>
                        <div className={css.contractDurationContainer}>
                          <div className={css.flexGrow}>
                            <TextField placeholder={"Duration"} type='number' name={'duration'} value={logisticFormik.values.duration} onChange={logisticFormik.handleChange} />
                            <div className={css.errorMessage}>{logisticFormik.errors.duration}</div>
                          </div>
                          <div>
                            <select className={css.inputSelect} name='durationType' value={logisticFormik.values.durationType} onChange={logisticFormik.handleChange}>
                              <option value="">Select</option>
                              <option value="days">Days</option>
                              <option value="months">Months</option>
                              <option value="years">Years</option>
                            </select>
                            <div className={css.errorMessage}>{logisticFormik.errors.durationType}</div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <div className={css.label}>Estimated Budget</div>
                      <div className={css.budgetContainer}>
                        <TextField placeholder={"Currency"} name={'currency'} value={logisticFormik.values.currency} onChange={logisticFormik.handleChange} errorMessage={logisticFormik.errors.currency} />
                        <div>
                          <select className={css.inputSelect} name='budgetType' value={logisticFormik.values.budgetType} onChange={logisticFormik.handleChange}>
                            <option value="">Select</option>
                            <option value="hour">Per Hour</option>
                            <option value="day">Per Day</option>
                            <option value="month">Per Month</option>
                          </select>
                          <div className={css.errorMessage}>{logisticFormik.errors.budgetType}</div>
                        </div>
                        <TextField placeholder={"0"} type='number' name={'budgetRangeA'} value={logisticFormik.values.budgetRangeA} onChange={logisticFormik.handleChange} errorMessage={logisticFormik.errors.budgetRangeA}/>
                        <div className={css.centerFlex}>-</div>
                        <TextField placeholder={"0"} type='number' name={'budgetRangeB'} value={logisticFormik.values.budgetRangeB} onChange={logisticFormik.handleChange} errorMessage={logisticFormik.errors.budgetRangeB}/>
                      </div>
                    </div>
                    <div>
                      <div className={css.label}>Workplace Location</div>
                      <select className={css.inputSelect} name='workplace' value={logisticFormik.values.workplace} onChange={logisticFormik.handleChange}>
                        <option value="">Select</option>
                        <option value="onsite">On-site</option>
                        <option value="remote">Remote</option>
                        <option value="hybird">Hybrid</option>
                      </select>
                      <div className={css.errorMessage}>{logisticFormik.errors.workplace}</div>
                    </div>
                    <div>
                      <div className={css.label}>Project Location</div>
                      <TextField placeholder={"Select Location"} name='location' value={logisticFormik.values.location} onChange={logisticFormik.handleChange} errorMessage={logisticFormik.errors.location} />
                    </div>
                    <div>
                      <div className={css.label}>Preffered Talent Location</div>
                      <TextField placeholder={"Input Location"} name='talentLocation' value={logisticFormik.values.talentLocation} onChange={logisticFormik.handleChange} errorMessage={logisticFormik.errors.talentLocation} />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={css.right}>
            </div>
          </div>
          <div className={css.footer}>
            <div className={css.footerContainer}>
              <div><ProgressBar progress={(page / 2) * 100} /></div>
              <div className={css.footerBtnContainer}>
                <Button color='gray' size='medium' onClick={handleClickBack} disabled={page === 1}>Back</Button>
                <Button color='black' size='medium' onClick={page === 1 ? basicFormik.handleSubmit : logisticFormik.handleSubmit}>{page < 2 ? 'Next' : 'Save the job'}</Button>
              </div>
            </div>
          </div>
          <LoadingOverlayScreen loading={loading} />
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  )
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
};

export default compose(connect(mapStateToProps))(EditJobPage);
