import React from 'react';

import css from './CreatorsCard.module.css';
import SimpleAvatar from '../../../../components/SimpleAvatar/SimpleAvatar';

import tiktokLogo from '../../../../assets/icons/tiktokLogo.svg';
import instagramLogo from '../../../../assets/icons/instagramLogo.svg';
import placeholderImage from '../../../../assets/image-placeholder.png';

const CreatorsCard = ({ creator, handleOnSelect, isSelected }) => {
  return (
    <div className={css.card}>
      <div className={css.header}>
        <div className={css.headerInfo}>
          <div className="avatar">
            <SimpleAvatar imageUrl={creator.creatorsAvatar} />
          </div>

          <div>
            <div className={css.name}>{creator.creatorsName}</div>
            <div className={css.type}>{creator.creatorsType}</div>
          </div>
        </div>

        <div>
          <input className={css.checkbox} type="checkbox" onClick={() => handleOnSelect(creator)} checked={isSelected} />
        </div>
      </div>

      <div>
        {creator.video ? (
          <video controls className={css.image}>
            <source src={creator.video} type="video/mp4"/>
          </video>
        ) : (
          <img className={css.image} src={creator.image ?? placeholderImage} />
        )}
      </div>

      <div className={css.footer}>
        <div className={css.tagContainer}>
          {creator.tags.slice(0, 4).map((item, index) => {
            if (index < 3) {
              return <div className={css.tag} key={`${item}-${index}`}>{item}</div>;
            }
            return <div className={css.tagMore} key={`${item}-${index}`}>+{creator.tags.slice(3).length}</div>;
          })}
        </div>
        <div className={css.priceAndUrl}>
          <div>
            <div className={css.fromText}>From</div>
            <div className={css.priceText}>{creator.price} SGD</div>
          </div>
          <div style={{ display: 'flex', gap: 8 }}>
            {creator.instagram && (
              <a href={creator.instagram} target='_blank'>
                <img className={css.url} src={instagramLogo} style={{ width: 24 }} />
              </a>
            )}
            {creator.instagram && (
              <a href={creator.tiktok} target='_blank'>
                <img className={css.url} src={tiktokLogo} style={{ width: 24 }} />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorsCard;
