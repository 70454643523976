import React, { useEffect, useState } from 'react';
import CreatorsCard from './components/CreatorsCard/CreatorsCard';
import Page from '../../components/Page/Page';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';

import css from './index.module.css';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import CountryFilter from '../CategoriesPage/Filters/CountryFilter';
import CreatorTypeFilter from '../CategoriesPage/Filters/CreatorType';
import NicheFilter from '../CategoriesPage/Filters/NicheFilter';
import ContentFilter from '../CategoriesPage/Filters/ContentFilter';
import LanguageFilter from '../CategoriesPage/Filters/LanguageFilter';
import GenderFilter from '../CategoriesPage/Filters/GenderFilter';
import Sort from '../CategoriesPage/Sort/Sort';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import searchImage from '../../assets/search.svg';
import { BlackButton } from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import axiosInstance from '../../axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import toast from 'react-hot-toast';
import { ArrowPagination } from '../../components/PaginationCollection/ArrowPagination/ArrowPagination';
import LoadingOverlayScreen from '../../components/LoadingOverlayScreen/LoadingOverlayScreen';
import { isEqual } from 'lodash';

const AddCreatorsToJobPage = props => {
  const { currentUser, intl } = props;

  const history = useHistory();

  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [displayedListings, setDisplayedListings] = useState([]);

  const [searchKey, setSearchKey] = useState('');
  const [countryFilter, setCountryFilter] = useState([]);

  const { id } = useParams();
  const getCampaignData = async (id) => {
    const response = await axiosInstance.get(`/public-campaigns/detail/${id}`);
    const { error, data } = await response.data;
    setCampaign(data);
  };
  useEffect(() => {
    if (id) {
      getCampaignData(id);
    }
  }, [id]);

  const fetchListings = async () => {
    let currentPage = 1;
    let hasMore = true;
    let filteredListings = [];
    let includedArrayList = [];

    try {
      setLoading(true);

      while (hasMore) {
        const listingResponse = await axiosInstance.get(`/sharetribe/listing/all?page=${currentPage}`);
        const { data } = await listingResponse.data.data;

        const { page, paginationLimit, totalPages, totalItems } = data.meta;
        const includedArray = data.included;
        includedArrayList = [...includedArrayList, ...includedArray];

        const withoutPublicCampaign = data.data.filter(listing => !listing.attributes.publicData.campaignId);
        filteredListings = [...filteredListings, ...withoutPublicCampaign];

        hasMore = page < totalPages;
        currentPage++;
      }


      for (const listing of filteredListings) {
        const authorRelation = includedArrayList.find(item => item.id.uuid === listing.relationships.author.data.id.uuid);

        const authorRelationData = authorRelation.attributes;
        const authorPublicData = authorRelation.attributes.profile.publicData;

        listing.authorEmail = authorRelationData.email;
        listing.tiktokLink = authorPublicData.tiktok;
        listing.instagramLink = authorPublicData.instagram;

        const profileImageId = authorRelation.relationships.profileImage.data ? authorRelation.relationships.profileImage.data.id.uuid : null;
        const profileImage = profileImageId ? includedArrayList.find(item => item.id.uuid === profileImageId).attributes.variants.default.url : null;
        listing.profileImage = profileImage;

        let listingImageId = null;
        let listingImageUrl = null;
        if (listing.relationships.images && listing.relationships.images.data.length > 0) {
          listingImageId = listing.relationships.images.data[0].id.uuid;
          const imageObject = includedArrayList.find(item => item.id.uuid === listingImageId);
          listingImageUrl = imageObject.attributes.variants.default.url;
        }
        listing.listingImageUrl = listingImageUrl;
      }

      setListings(filteredListings.map(listing => {
        const tags = [];
        let videoLink = null;

        if (listing.attributes.publicData.activity) {
          for (const tag of listing.attributes.publicData.activity) {
            if (tag !== null && tag !== undefined) {
              tags.push(tag);
            }
          }
        }

        if (listing.attributes.publicData.category) {
          for (const tag of listing.attributes.publicData.category) {
            if (tag !== null && tag !== undefined) {
              tags.push(tag);
            }
          }
        }

        if (listing.attributes.publicData.videos && listing.attributes.publicData.videos.length > 0) {
          videoLink = listing.attributes.publicData.videos[0];
        }

        return ({
          creatorsAvatar: listing.profileImage ? listing.profileImage : 'https://mdbcdn.b-cdn.net/img/new/avatars/2.webp',
          creatorsName: listing.attributes.publicData.displayName ?? "-",
          creatorsType: listing.attributes.publicData.activity ? listing.attributes.publicData.activity : '-',
          location: listing.attributes.publicData.usCitizen ?? '',
          creatorsEmail: listing.authorEmail,
          video: videoLink,
          image: listing.listingImageUrl,
          tags: tags,
          price: listing.attributes.price ? listing.attributes.price.amount : 0,
          instagram: listing.instagramLink,
          tiktok: listing.tiktokLink,
        })
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchListings();
  }, [])

  const [selectedCreators, setSelectedCreators] = useState([]);

  const handleOnSelect = creator => {
    const targetEmail = creator.creatorsEmail;
    if (selectedCreators.some((selected) => isEqual(selected, creator))) {
      setSelectedCreators(prevState => {
        return prevState.filter((item) => item.creatorsEmail !== targetEmail);
      });
    } else {
      setSelectedCreators(prevState => {
        return [...prevState, creator];
      });
    }
  };

  const onClickInviteSelectedCreators = async () => {
    try {
      setOverlayLoading(true);

      const clientBio = currentUser.attributes.profile.bio;

      const uniqueCreators = selectedCreators.filter((item, index, self) =>
        index === self.findIndex((t) => t.creatorsEmail === item.creatorsEmail)
      );

      const sendEmailsResponse = await axiosInstance.post('/job/applicant/invite/email/manual', {
        recipientList: uniqueCreators,
        jobId: id,
        clientBio,
      });
      const { error, data, message } = await sendEmailsResponse.data;

      if (!error) {
        toast.success(message);
        setSelectedCreators([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while sending the invitation');
    } finally {
      setOverlayLoading(false);
      setSelectedCreators([]);
    }
  }

  const itemPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const onPageChange = (newCurrentPage) => {
    setCurrentPage(newCurrentPage);
  }

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = displayedListings.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (listings) {
      setCurrentPage(1);

      let filteredListings = listings.filter(listing => {
        return listing.creatorsName?.toLowerCase().includes(searchKey?.toLowerCase());
      });
      if (countryFilter.length > 0) {
        filteredListings = filteredListings.filter(listing => {
          return countryFilter.includes(listing.location);
        });
      }
      setDisplayedListings(filteredListings);
      setTotalPages(Math.ceil(filteredListings.length / 20));
    }
  }, [listings, searchKey, countryFilter]);

  return (
    <Page title={'Add Creators'} scrollingDisabled={false}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={'AddCreatorsToJobPage'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab={'AddCreatorsToJobPage'}
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.content}>
            <div className={css.title}>Add creators to your Campaign</div>
            <div className={css.search}>
              <img src={searchImage} />
              <Form
                onSubmit={(value) => {
                  const { search } = value;
                  setSearchKey(search ?? '');
                }}
                render={({ handleSubmit, form }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Field name="search" component="input" placeholder="Search" />
                    </form>{' '}
                    <svg
                      onClick={() => {
                        form.reset();
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12 4.00003L4 12M3.99997 4L11.9999 12"
                        stroke="#656565"
                        stroke-width="1.2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </>
                )}
              />
            </div>

            <div className={css.filterContainer}>
              <CountryFilter
                urlQueryParams={{}}
                onSubmit={(countrCodeList) =>
                  setCountryFilter(countrCodeList.pub_nationality.split(','))
                }
                history={history}
              />
              <CreatorTypeFilter urlQueryParams={{}} onSubmit={() => {}} history={history} />
              <NicheFilter urlQueryParams={{}} onSubmit={() => {}} history={history} />
              <ContentFilter urlQueryParams={{}} onSubmit={() => {}} history={history} />
              <LanguageFilter urlQueryParams={{}} onSubmit={() => {}} history={history} />
              <GenderFilter urlQueryParams={{}} onSubmit={() => {}} history={history} />

              <Sort counter={listings.length} onSelect={() => {}} urlQueryParams={{}} />
            </div>

            {loading ? (
              <div className={css.center}>
                <Loading type='animation' />
              </div>
            ) : (
              <div className={css.cardContainer}>
                {currentItems.map((item, index) => (
                  <CreatorsCard key={`${index}-${item.creatorsName}`} creator={item} handleOnSelect={handleOnSelect} isSelected={selectedCreators.some((selected) => selected.creatorsEmail === item.creatorsEmail)} />
                ))}
              </div>
            )}

            {currentItems.length > 0 && (
              <div>
                <ArrowPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newCurrentPage) => onPageChange(newCurrentPage)}
                />
              </div>
            )}
          </div>

          <div className={css.actionsContainer}>
            <div className={css.actions}>
              <div className={css.selectedText}>{selectedCreators.length} selected</div>
              <BlackButton className={css.actionButton} onClick={onClickInviteSelectedCreators}>
                Invite Selected Creators
              </BlackButton>
            </div>
          </div>
          <LoadingOverlayScreen isLoading={overlayLoading} />
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return { currentUser };
};

export default compose(connect(mapStateToProps), injectIntl)(AddCreatorsToJobPage);
