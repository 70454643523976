import css from './RejectedTable.module.css'
import instagramIcon from '../../../../../../assets/icons/instagramLogo.svg';
import tiktokIcon from '../../../../../../assets/icons/tiktokLogo.svg';
import { ArrowPagination } from '../../../../../../components/PaginationCollection/ArrowPagination/ArrowPagination';
import { useState } from 'react';
import { formatDateString } from '../../../../../../util/string';
import { Button } from '../../../../../../components/New/Button/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const tableHeaders = [
  { key: 'creator', label: 'Creators' },
  { key: 'socialMedia', label: 'Social Media Links' },
  { key: 'usageDate', label: 'Date' },
  { key: 'reject', label: 'Make an offer' }
];

export default function RejectedTable ({
  applicants,
  onClickOpenConfirmDialog
}) {
  const history = useHistory();
  const headers = tableHeaders;
  const iconMap = {
    tiktok: tiktokIcon,
    instagram: instagramIcon,
  }

  const itemsPerPage = 20;
  const totalPages = Math.ceil(applicants.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (newCurrentPage) => {
    setCurrentPage(newCurrentPage);
  }

  // Calculate the applicants to display for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentApplicants = applicants.slice(startIndex, endIndex);

  return (
    <div>
      <table className={css.table}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.key}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentApplicants.map((applicant, index) => (
            <tr key={`tr-${applicant.id}-${index}`}>
              <td>
                <div className={css.creatorContainer} onClick={() => history.push(`/creator/${applicant.creator.id}`)}>
                  <img src={applicant.creator.avatar} alt="Creator" className={css.creatorAvatar} />
                  <div>{applicant.creator.displayName}</div>
                </div>
              </td>
              <td>
                <div className={css.socialMediaContainer}>
                  {applicant.creator.instagram && <a href={applicant.creator.instagram} target='_blank'>
                    <img src={iconMap.instagram} alt="Social Media" className={css.socialMediaIcon} />
                  </a>}
                  {applicant.creator.tiktok && <a href={applicant.creator.tiktok} target='_blank'>
                    <img src={iconMap.tiktok} alt="Social Media" className={css.socialMediaIcon} />
                  </a>}
                </div>
              </td>
              <td>
                <div>{formatDateString(applicant.createdAt)}</div>
              </td>
              <td>
                <div className={css.actionContainer}>
                  <Button size='small' color='gray' onClick={() => onClickOpenConfirmDialog(applicant, 'shortlist')}>Return to shortlist</Button>
                </div>
              </td>
            </tr>
          ))}
          {/* Pagination */}
          {currentApplicants.length > 0 ? (
            <tr>
              <td colSpan={headers.length}>
                <ArrowPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={newCurrentPage => onPageChange(newCurrentPage)}
                />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={headers.length}>
                <div className={css.noApplicantsText}>No applicants to display</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
