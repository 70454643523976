import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BlackButton } from "../../Button/Button";
import ModalV2Simple from "../../ModalV2/ModalV2Simple";
import css from './ConnectStripeDialog.module.css';

export const ConnectStripeDialog = ({
  isOpen,
  onClose,
  width = 500,
}) => {
  const history = useHistory();

  return (
    <ModalV2Simple
      isOpen={isOpen}
      onClose={onClose}
      title={"Complete Your Wallet Setup to Proceed"}
      width={width}
    >
      <div className={css.container}>
        <div className={css.text}>
          Before you can apply for this paid opportunity, we need to ensure that your payout details are set up and verified with Stripe. This allows us to securely process your payments for this and any future PAID campaigns.
        </div>
        <div className={css.text}>
          Please take a moment to register and verify your information with Stripe. Once completed, you'll be able to return and finalize your application.
        </div>
        <div>
          <BlackButton className={css.btn} onClick={() => history.push('/account/payments')}>Add Payout Details</BlackButton>
        </div>
      </div>
    </ModalV2Simple>
  );
}
