import css from './EmailJobInviteCreatorDialog.module.css';
import ModalV2Simple from "../../ModalV2/ModalV2Simple"
import { useFormik } from 'formik';
import { TextField } from '../../New/TextField/TextField';
import { TextAreaField } from '../../New/TextAreaField/TextAreaField';
import { Button } from '../../New/Button/Button';
import * as Yup from 'yup';
import axiosInstance from '../../../axios';
import toast from 'react-hot-toast';

export const EmailJobInviteCreatorDialog = ({
  open,
  onClose,
  selfEmail = null,
  jobId,
}) => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      subject: '',
      body: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string(),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.string(),
      subject: Yup.string().required('Required'),
      body: Yup.string().required('Required'),
    }),
    validateOnChange: false,
    onSubmit: values => { }
  });

  const onClickSendEmail = async (mode) => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      toast.error('Please fill in all required fields');
    } else {
      // send email
      const { error, message } = axiosInstance.post('/job/applicant/invite/email/single', {
        recipientEmail: mode === 'test' ? selfEmail : formik.values.email,
        emailSubject: formik.values.subject,
        emailBody: formik.values.body,
        jobId: jobId,
      })
      if (error) {
        toast.error('Failed to send email, ' + message);
      } else {
        mode === 'test' ?
          toast.success(`Test email was sent to ${selfEmail}`) :
          toast.success(`Email sent to ${formik.values.email}`);
        onClose();
      }
    }
  }

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      title={"Invite Creator By Email"}
      width={600}
    >
      <div className={css.container}>
        <div className={css.subtitle}>Please enter creator contact information, the email subject and body to proceed with the invitation.</div>
        <div className={css.grid_2}>
          <TextField name={"firstName"} value={formik.values.firstName} onChange={formik.handleChange} placeholder={"First name"} errorMessage={formik.errors.firstName} />
          <TextField name={"lastName"} value={formik.values.lastName} onChange={formik.handleChange} placeholder={"Last name"} errorMessage={formik.errors.lastName} />
          <TextField name={"email"} value={formik.values.email} onChange={formik.handleChange} placeholder={"Email"} errorMessage={formik.errors.email} />
          <TextField name={"phone"} value={formik.values.phone} onChange={formik.handleChange} placeholder={"Phone (optional)"} errorMessage={formik.errors.phone} />
        </div>
        <div className={css.emailContainer}>
          <TextField name={"subject"} value={formik.values.subject} onChange={formik.handleChange} placeholder={"Email's subject"} errorMessage={formik.errors.subject} />
          <TextAreaField name={"body"} value={formik.values.body} onChange={formik.handleChange} placeholder={"Email's body"} errorMessage={formik.errors.body} />
        </div>
        <div className={css.grid_2}>
          <Button color='gray' onClick={() => onClickSendEmail('test')}>Test the Email</Button>
          <Button fullWidth onClick={onClickSendEmail}>Invite Creator</Button>
        </div>
      </div>
    </ModalV2Simple>
  )
}
