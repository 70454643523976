import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  Switcher,
} from '../../../../components';
import css from './ClientViewJobPage.module.css';
import TopbarContainer from '../../../TopbarContainer/TopbarContainer';
import { Button } from '../../../../components/New/Button/Button';
import addIcon from '../../../../assets/icons/add.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import axiosInstance from '../../../../axios';
import { camelToLabel } from '../../../../util/string';
import toast from 'react-hot-toast';
import { Chip } from '../../../../components/NewChip/Chip';
import ModalV2Simple from '../../../../components/ModalV2/ModalV2Simple';
import { TextField } from '../../../../components/New/TextField/TextField';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../../../atoms/global';
import { isEmpty } from 'lodash';
import { formatStringDate } from '../../../../util/dates';
import Loading from '../../../../components/Loading/Loading';

const tableHeaders = [
  { key: 'title', label: 'Job Title' },
  { key: 'status', label: 'Status' },
  { key: 'candidates', label: 'Candidates' },
  { key: 'deadline', label: 'Application Deadline' },
  { key: 'actions', label: 'Actions' }
];

const switcherItems = [
  { label: 'All', key: 'all' },
  { label: 'Active', key: 'active' },
  { label: 'Closed', key: 'closed' },
];

const ClientViewJobPage = ({ currentUser }) => {
  const history = useHistory();
  const setGlobalLoading = useSetAtom(loadingAtom);

  const [switcherKey, setSwitcherKey] = useState(switcherItems[0].key);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const filteredJobs = jobs.filter(job => switcherKey === 'all' || job.status === switcherKey);

  const fetchJobs = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/job/client/list/${currentUser.id.uuid}`);
      const { error, message, data } = response.data;
      if (error) {
        toast.error(message);
        return;
      }

      setJobs(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (currentUser) {
      fetchJobs();
    }
  }, [currentUser]);


  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newJobTitle, setNewJobTitle] = useState('');
  const [newJobErrorMessage, setNewJobErrorMessage] = useState('');
  const onClickCreateJob = async () => {
    try {
      setGlobalLoading(true);

      if (isEmpty(newJobTitle)) {
        setNewJobErrorMessage('Please enter a job title');
        return;
      }

      const response = await axiosInstance.post('/job/create', {
        title: newJobTitle,
        clientId: currentUser.id.uuid,
      })

      const { error, message, data } = response.data;
      if (error) {
        toast.error(message);
        return;
      }

      history.push(`/job/client/edit/${data.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteJob, setDeleteJob] = useState(null);
  const onClickDeleteJob = async (job) => {
    setOpenDeleteDialog(true);
    setDeleteJob(job);
  }
  const handleDeleteJob = async () => {
    try {
      setGlobalLoading(true);

      const response = await axiosInstance.post(`/job/delete/${deleteJob.id}`);
      const { error, message } = response.data;

      if (error) {
        toast.error(message);
        return;
      }

      await fetchJobs();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }
  const handleActivateJob = async (jobId) => {
    try {
      setGlobalLoading(true);

      const response = await axiosInstance.post(`/job/update/${jobId}`, {
        status: 'active',
      });
      const { error, message } = response.data;

      if (error) {
        toast.error(message);
        return;
      }

      await fetchJobs();
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }

  return (
    <Page
      title={"Job Page"}
      scrollingDisabled={false}
    >
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={'ClientJobPage'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab={'ClientJobPage'}
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.headerContainer}>
            <div>
              <div className={css.headerTitle}>Jobs</div>
            </div>
            <Button color='black'>
              <div className={css.newBtnContainer} onClick={() => setOpenCreateDialog(true)}>
                <img src={addIcon} alt='add' />
                New Job
              </div>
            </Button>
          </div>
          <div className={css.contentContainer}>
            <div>
              <Switcher
                activeKey={switcherKey}
                setActiveKey={setSwitcherKey}
                onChange={() => {}}
                items={switcherItems}
                fullWidth
              />
            </div>
            {loading ? (
              <Loading type='animation' />
            ) : (
              <table className={css.table}>
                <thead>
                  <tr>
                    {tableHeaders.map((header) => (
                      <th key={header.key} style={ header.label === 'Actions' ? { textAlign: 'center' } : {}}>{header.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jobs.length === 0 ? (
                    <tr>
                      <td colSpan={5} style={{ textAlign: 'center' }}>No Jobs Found</td>
                    </tr>
                  ) : (
                    <>
                      {filteredJobs.map((job) => (
                        <tr>
                          <td><div onClick={() => history.push(`/job/client/detail/${job.id}`)}>{job.title}</div></td>
                          <td>
                            <Chip color={job.status === 'active' ? 'success' : job.status === 'draft' ? 'default' : 'red'}>{camelToLabel(job.status)}</Chip>
                          </td>
                          <td>{job.jobApplicants.length}</td>
                          <td>{formatStringDate(job.applicationEndDate)}</td>
                          <td>
                            <div className={css.jobButtonContainer}>
                              <Button color='gray' size='medium' onClick={() => history.push(`/job/client/edit/${job.id}`)}>Edit</Button>
                              <Button color='gray' size='medium' onClick={() => onClickDeleteJob(job)}>{job.status === 'active' ? 'Close' : 'Delete'}</Button>
                              {job.status === 'closed' && (
                                <Button color='gray' size='medium' onClick={() => handleActivateJob(job.id)}>Open</Button>
                              )}
                              <Button color='gray' size='medium' onClick={() => history.push(`/job/client/detail/${job.id}`)}>Manage Applicants</Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <ModalV2Simple
            isOpen={openCreateDialog}
            onClose={() => setOpenCreateDialog(false)}
            title={'Post a new job'}
            width={500}
          >
            <div className={css.newJobTitle}>Enter a job title</div>
            <TextField value={newJobTitle} onChange={(e) => setNewJobTitle(e.target.value)} placeholder={"e.g. UGC Content Creator"} errorMessage={newJobErrorMessage} />
            <div className={css.newJobButtonContainer}>
              <Button color='black' onClick={onClickCreateJob} fullWidth>
                Next
              </Button>
            </div>
          </ModalV2Simple>
          <ModalV2Simple
            isOpen={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            title={deleteJob?.status === 'active' ? 'Confirm Close Job' : 'Confirm Job Deletion'}
            width={500}
          >
            <div className={css.deleteJobTitle}>Are you sure you want to {deleteJob?.status === 'active' ? 'close' : 'delete'} this Job? This action cannot be undone.</div>
            <div className={css.deleteJobButtonContainer}>
              <Button color='gray' onClick={() => setOpenDeleteDialog(false)} fullWidth>Cancel</Button>
              <Button color='black' onClick={handleDeleteJob} fullWidth>Confirm</Button>
            </div>
          </ModalV2Simple>
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  )
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
};

export default compose(connect(mapStateToProps))(ClientViewJobPage);
