import { formatStringDate } from '../../util/dates';
import { camelToLabel } from '../../util/string';
import { Button } from '../New/Button/Button';
import { Chip } from '../NewChip/Chip';
import css from './JobCard.module.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const JobCard = ({ job }) => {
  const history = useHistory();

  if (!job) {
    return null;
  }

  return (
    <div className={css.card}>
      <div className={css.header}>
        <div className={css.clientContainer}>
          <img src={job.client.avatar} alt="client avatar" className={css.clientAvatar} />
          <div className={css.clientName}>{job.client.name}</div>
        </div>
        <div className={css.postTime}>{formatStringDate(job.applicationEndDate)}</div>
      </div>
      <div className={css.content}>
        <div className={css.title}>{job.title}</div>
        <div className={css.contentChip}>
          <Chip>{camelToLabel(job.type)}</Chip>
          <Chip>{camelToLabel(job.workplace)}</Chip>
        </div>
      </div>
      <div className={css.footer}>
        <div>
          <div className={css.currency}>{job.currency} {job.budgetRangeA}{job.budgetRangeB && ` - ${job.budgetRangeB}`}</div>
          <div className={css.location}>{job.location}</div>
        </div>
        <Button size='medium' onClick={() => history.push(`/job/creator/apply/${job.id}`)}>View job</Button>
      </div>
    </div>
  )
}
