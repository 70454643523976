import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config'; //unneccessary?
import logo from './logo.png';
import css from './Logo.module.css';
import logoWhiteNew from './logo-white.png';
import wildLogo from './wildLogo.png';

const Logo = props => {
  const { className, format, footerLogo, isDark, ...rest } = props;
  const isMobile = format !== 'desktop';
  const classes = classNames(className, { [css.logoMobile]: isMobile });
  const logoImage = isMobile ? logo : logo;
  const logoImageWhite = isMobile ? logoWhiteNew : logoWhiteNew;

  let renderLogo = null;
  if (isDark) {
    renderLogo = logoImageWhite;
  } else {
    renderLogo = logoImage;
  }

  if (footerLogo) {
    renderLogo = logoImageWhite;
  }

  return (
    <img
      className={classes}
      src={renderLogo}
      alt={config.siteTitle}
      {...rest}
    />
  );
};

const { oneOf, string, bool } = PropTypes; //redundant declaration?

Logo.defaultProps = {
  className: null,
  format: 'desktop',
  footerLogo: false,
  isDark: false,
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
  footerLogo: bool,
  isDark: bool,
};

export default Logo;
