import { useFormik } from "formik";
import ModalV2Simple from "../../ModalV2/ModalV2Simple"
import { TextAreaField } from "../../New/TextAreaField/TextAreaField";
import { TextField } from "../../New/TextField/TextField";
import css from './JobApplyDialog.module.css';
import * as Yup from 'yup';
import axiosInstance from "../../../axios";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../../New/Button/Button";
import Loading from "../../Loading/Loading";
import uploadIcon from '../../../assets/icons/upload.svg';
import toast from "react-hot-toast";
import { camelToLabel } from "../../../util/string";
import { uploadFile } from "../../../util/api";

export const ApplyJobDialog = ({
  open,
  onClose,
  job,
  userId,
  onApply,
}) => {
  const [loading, setLoading] = useState(false);
  const [applied, setApplied] = useState(false);
  const [inputDocuments, setInputDocuments] = useState([]);

  useEffect(() => {
    if (job) {
      setInputDocuments(job.documents.map(document => ({ name: document, link: '', file: null })));
    }
  }, [job]);

  const onClickSelectFile = (documentName) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;
    input.onchange = (input) => {
      const targetFile = input.target.files[0];
      console.log(targetFile);
      const newDocuments = inputDocuments.map(document => {
        if (document.name === documentName) {
          return {
            ...document,
            file: targetFile,
          }
        }
        return document;
      });
      setInputDocuments(newDocuments);
    }

    input.click();
  }

  const onClickApply = async () => {
    try {
      setLoading(true);

      let creatorDocuments = [];
      // upload all the files
      for (const documentFile of inputDocuments) {
        if (documentFile.file) {
          const formData = new FormData();
          formData.append('file', documentFile.file);
          formData.append('id', `${Date.now()}`);

          const { url } = await uploadFile(formData);
          creatorDocuments.push({
            name: documentFile.name,
            link: url,
          });
        }else{
          creatorDocuments.push({
            name: documentFile.name,
            link: documentFile.link,
          });
        }
      }

      const response = await axiosInstance.post(`/job/creator/apply`, {
        jobId: job.id,
        userId,
        name: formik.values.name,
        email: formik.values.email,
        phone: formik.values.phone,
        cover: formik.values.cover,
        documents: creatorDocuments,
      });
      const { error, message } = response.data;

      if (!error) {
        toast.success("Applied to job successfully");
        setApplied(true);
        onApply();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to apply to job');
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      cover: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      name: Yup.string().required('First name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      phone: Yup.string().required('Phone number is required'),
      cover: Yup.string().required('Cover letter is required'),
    }),
    onSubmit: onClickApply,
  })

  const fetchUser = async () => {
    const response = await axiosInstance.get(`/user/detail/${userId}`)
    const { erorr, data } = response.data;
    formik.resetForm({
      values: {
        name: data.name,
        email: data.email,
        phone: data.phone,
        cover: '',
      }
    })
  }
  useEffect(() => {
    if (!isEmpty(userId)) {
      fetchUser();
    }
  }, [userId]);

  const onClickRemoveSelectedFile = (documentName) => {
    const newDocuments = inputDocuments.map(document => {
      if (document.name === documentName) {
        return {
          ...document,
          file: null,
        }
      }
      return document;
    });
    setInputDocuments(newDocuments);
  }

  const handleLinkChange = async (link, documentName) => {
    const newDocuments = inputDocuments.map(document => {
      if (document.name === documentName) {
        return {
          ...document,
          link,
        }
      }
      return document;
    });
    setInputDocuments(newDocuments);
  }

  if (!job || !userId) {
    return null;
  }

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      title={applied ? 'Job Application Sent' : 'Fill the job application'}
      width={600}
    >
      {applied && (
        <div>Your job application has been sent. You will be contacted if you fit the job.</div>
      )}
      {!applied && (
        <div className={css.root}>
          <div>
            <div className={css.formGrid}>
              <div className={css.grid2}>
                <TextField name={'name'} placeholder={'Name'} value={formik.values.name} onChange={formik.handleChange} errorMessage={formik.errors.name} />
              </div>
              <TextField name={'email'} placeholder={'Email'} value={formik.values.email} onChange={formik.handleChange} errorMessage={formik.errors.email} />
              <TextField name={'phone'} placeholder={'Phone number'} value={formik.values.phone} onChange={formik.handleChange} errorMessage={formik.errors.phone} />
            </div>
          </div>
          <div>
            <div className={css.label}>Cover letter</div>
            <TextAreaField name={'cover'} placeholder={'Cover Letter'} onChange={formik.handleChange} errorMessage={formik.errors.cover} />
          </div>
          {job.documents.length === 0 ? (
            <div className={css.textCenter}>No Document Required</div>
          ) : (
            <>
              {job.documents.map((document, index) =>
              {
                const selectedFile = inputDocuments.find(inputDocument => inputDocument.name === document)?.file ?? null;

                return (
                  <div key={index}>
                    <div className={css.label}>{camelToLabel(document)}</div>
                    <div className={css.formGrid}>
                      <Button size="medium" color="gray" onClick={() => {selectedFile ? onClickRemoveSelectedFile(document) : onClickSelectFile(document)} }>
                        {selectedFile ? (
                          <div>
                            <div style={{ marginRight: '8px' }}>{selectedFile.name}</div>
                            <div>Remove</div>
                          </div>
                        ) : (
                          <div>
                            <img src={uploadIcon} alt="upload Icon"/> Attach file
                          </div>
                        )}
                      </Button>
                      <TextField name={`document-${index}`} placeholder={`Enter ${document} link`} disabled={selectedFile} onChange={(e) => handleLinkChange(e.target.value, document)}/>
                    </div>
                  </div>
                )
              })}
            </>
          )}
          <div style={{ marginTop: '8px' }}>
            Attachment will override links
            <Button fullWidth disabled={loading} onClick={formik.handleSubmit}>
              <div className={css.btnContent}>
                Apply
                {loading && <Loading />}
              </div>
            </Button>
          </div>
        </div>
      )}
    </ModalV2Simple>
  )
}
