import css from "./AuthDialog.module.css"
import ModalV2Simple from "../../ModalV2/ModalV2Simple"
import { TextField } from "../../New/TextField/TextField"
import { useFormik } from "formik"
import { Button } from "../../New/Button/Button"
import * as Yup from "yup";
import { useEffect } from "react"

export const AuthDialog
= ({
  open,
  onClose,
  onClickLogin,
}) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: values => {
      onClickLogin(values.email, values.password);
    }
  });

  useEffect(() => {
    localStorage.setItem('signup-redirect-creator', window.location.pathname);
  }, []);

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      width={500}
      title={"Sign In to continue"}
    >
      <div className={css.formContainer}>
        <TextField name={'email'} value={formik.values.email} onChange={formik.handleChange} placeholder={'Enter your email'} errorMessage={formik.errors.email}/>
        <TextField name={'password'} value={formik.values.password} onChange={formik.handleChange} placeholder={'Enter your password'} errorMessage={formik.errors.password}/>
        <Button size="medium" onClick={formik.handleSubmit}>Log In</Button>
        <div className={css.signUpText}>Don't have an account? <a href="/signup">Sign Up</a></div>
      </div>
    </ModalV2Simple>
  )
}
