import { useEffect, useState } from 'react';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  Page,
  Switcher,
} from '../../../../components';
import TopbarContainer from '../../../TopbarContainer/TopbarContainer';
import css from './DetailJobPage.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import AddMoreCreators from './Components/AddMoreCreators/AddMoreCreators';
import CampaignDetailCard, { JobDetailCard } from './Components/CampaignDetailCard/JobDetailCard';
import ellipsis_icon from '../../../../assets/icons/ellipsis_horizontal.svg';
import AppliedTable from './Components/AppliedTable/AppliedTable';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import ShortlistTable from './Components/ShortlistTable/ShortlistTable';
import RejectedTable from './Components/RejectedTable/RejectedTable';
import { ContractingContent } from './Components/ContractingContent/ContractingContent';
import axiosInstance from '../../../../axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { initializeCardPaymentData } from '../../../../ducks/stripe.duck';
import { getMarketplaceEntities } from '../../../../ducks/marketplaceData.duck';
import toast from 'react-hot-toast';
import { camelToLabel, formatDateString } from '../../../../util/string';
import { PublicCampaignStatus } from '../../../../util/enums';
import { CompletedContent } from './Components/CompletedContent/CompletedContent';
import checkIsCreator from '../../../../util/checkIsCreator';
import ModalV2Simple from '../../../../components/ModalV2/ModalV2Simple';
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlayScreen from '../../../../components/LoadingOverlayScreen/LoadingOverlayScreen';
import Loading from '../../../../components/Loading/Loading';
import { ConfirmationDialog } from '../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import { CampaignEmailInviteCreatorDialog } from '../../../../components/Dialogs/EmailInviteCreatorDialog/EmailInviteCreatorDialog';
import { Button } from '../../../../components/New/Button/Button';
import uploadIcon from '../../../../assets/icons/upload.svg';
import { uploadFile } from '../../../../util/api';
import { ClientCreatorEmailTemplateUrl } from '../../../../util/consts';
import { TextAreaField } from '../../../../components/New/TextAreaField/TextAreaField';
import { ChatContent } from './Components/ChatContent/ChatContent';
import { EmailJobInviteCreatorDialog } from '../../../../components/Dialogs/EmailJobInviteCreatorDialog/EmailJobInviteCreatorDialog';
import axios from 'axios';

export const switcherItems = [
  { label: 'Overview', key: 'overview' },
  { label: 'Applied', key: 'applied' },
  { label: 'Shortlist', key: 'shortlist' },
  { label: 'Contracting', key: 'contracting' },
  { label: 'Completed', key: 'completed' },
  { label: 'Rejected', key: 'rejected' },
];

const DetailJobPage = ({
  currentUser,
}) => {
  useEffect(() => {
    if (checkIsCreator(currentUser?.attributes?.profile?.protectedData)) {
      history.push('/campaigns');
    }
  }, [currentUser]);

  const { id } = useParams();
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchTarget = searchParams.get('section');
  useEffect(() => {
    setKey(searchTarget ? searchTarget : 'overview');
  }, [searchTarget]);

  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [overlayLoading, setOverlayLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [job, setJob] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [switcherItems, setSwitcherItems] = useState([
    { label: 'Overview', key: 'overview' },
    { label: 'Applied', key: 'applied' },
    { label: 'Shortlist', key: 'shortlist' },
    { label: 'Interview', key: 'interview' },
    { label: 'Job offered', key: 'offered' },
    { label: 'Rejected', key: 'rejected' },
  ]);
  const [key, setKey] = useState(switcherItems[0].key);

  const fetchCampaign = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/job/detail/${id}`);
      const { error, message, data } = await res.data;
      console.log(data);
      if (!error) {
        setJob(data);
        setApplicants(data.jobApplicants);

        const applicants = data.jobApplicants;
        const shortlistApplicants = applicants.filter(
          applicant => applicant.status === 'shortlist'
        );
        const rejectedApplicants = applicants.filter(
          applicant => applicant.status === 'rejected'
        );
        const interviewApplicants = applicants.filter(
          applicant => applicant.status === 'interview'
        );
        const offeredApplicants = applicants.filter(
          applicant => applicant.status === 'offered'
        );

        setSwitcherItems([
          { label: 'Overview', key: 'overview' },
          { label: 'Applied', key: 'applied', count: applicants.length },
          { label: 'Shortlist', key: 'shortlist', count: shortlistApplicants.length },
          { label: 'Interview', key: 'interview', count: interviewApplicants.length },
          { label: 'Job offered', key: 'offered', count: offeredApplicants.length },
          { label: 'Rejected', key: 'rejected', count: rejectedApplicants.length },
        ]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaign();
  }, [id]);

  const shortlistApplicants = applicants.filter(
    applicant => applicant.status === 'shortlist'
  );
  const rejectedApplicants = applicants.filter(
    applicant => applicant.status === 'rejected'
  );
  const interviewApplicants = applicants.filter(
    applicant => applicant.status === 'interview'
  );
  const offeredApplicants = applicants.filter(
    applicant => applicant.status === 'offered'
  );

  // Email Invite
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [emailType, setEmailType] = useState(null);
  const onClickEmailType = (type) => {
    setEmailType(type);
  }

  const fetchSingaporeUsersFromSharetribe = async () => {
    let currentPage = 1;
    let hasMore = true;
    let users = [];

    while (hasMore) {
      try {
        const response = await axiosInstance.get(`sharetribe/user/list/all?page=${currentPage}`);
        const { data } = await response.data.data;
        const { page, paginationLimit, totalPages, totalItems } = await data.meta;

        let filterOnlySGUsers = data.data.filter(user =>
          user.attributes.profile.publicData.usCitizen === 'SG' &&
          user.attributes.banned === false &&
          user.attributes.deleted === false &&
          user.attributes.profile.protectedData.isCreator === true
        );

        if (emailType !== null && emailType !== 'all') {
          filterOnlySGUsers = filterOnlySGUsers.filter(user => user.attributes.profile.publicData.gender?.toLowerCase() === emailType.toLowerCase());
        }

        users = [...users, ...filterOnlySGUsers];

        hasMore = page < totalPages;
        currentPage++;
      } catch (error) {
        console.error(error);
      }
    }

    const userEmails = users.map(user => user.attributes.email);
    return userEmails;
  }

  const sendCustomEmails = async (emailSubject, emailBody) => {
    const recipientList = await fetchSingaporeUsersFromSharetribe();

    if (recipientList.length === 0) {
      toast.error('No creators found to send email');
      return;
    }

    const response = await axiosInstance.post(`/job/applicant/invite/email/custom`, {
      recipientList,
      emailSubject,
      emailBody,
      jobId: id,
      emailType: emailType
    })
    const { error, message, data } = response.data;
    if (!error) {
      toast.success(message);
      setOpenInviteModal(false);
      emailFormik.resetForm();
      setInviteEvents([...inviteEvents, { type: emailType }]);
    } else {
      toast.error(message);
    }
  }


  // Reject Dialog
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openInviteInterviewDialog, setOpenInviteInterviewDialog] = useState(false);

  const [targetApplicant, setTargetApplicant] = useState(null);

  const onClickOpenConfirmDialog = (applicant, newStatus) => {
    setTargetApplicant(applicant);

    if (newStatus === 'rejected') {
      setOpenRejectDialog(true);
    } else {
      setOpenConfirmDialog(true);
    }
  }

  const onClickOpenInterviewDialog = (applicant) => {
    setTargetApplicant(applicant);
    setOpenInviteInterviewDialog(true);
  }

  const handleOfferApplicant = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(`/job/applicant/update/${targetApplicant.id}`, {
        status: 'offered',
      });
      await fetchCampaign();
      setOpenConfirmOfferDialog(false);
    } catch (error) {
      console.error(error);
      toast.error('Failed to reject applicant');
    } finally {
      setLoading(false);
    }
  }

  const onClickShortlist = async (applicantId) => {
    try {
      setLoading(true);
      await axiosInstance.post(`/job/applicant/update/${applicantId}`, {
        status: 'shortlist',
      });
      await fetchCampaign();
      toast.success('Applicant shortlisted');
    } catch (error) {
      console.error(error);
      toast.error('Failed to reject applicant');
    } finally {
      setLoading(false);
    }
  }

  const handleShortlistApplicant = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(`/job/applicant/update/${targetApplicant.id}`, {
        status: 'shortlist',
      });
      await fetchCampaign();
      setOpenConfirmDialog(false);
      toast.success('Applicant shortlisted');
    } catch (error) {
      console.error(error);
      toast.error('Failed to reject applicant');
    } finally {
      setLoading(false);
    }
  }

  const handleRejectApplicant = async (rejectMessage) => {
    try {
      setLoading(true);
      await Promise.allSettled([
        axiosInstance.post(`/job/applicant/update/${targetApplicant.id}`, {
          status: 'rejected',
          rejectMessage,
        }),
        axiosInstance.post(`/job/applicant/rejected/email/single`, {
          emailBody: rejectMessage,
          jobId: id,
          applicantId: targetApplicant.id,
        }),
      ]);
      await fetchCampaign();
      setOpenRejectDialog(false);
      toast.success('Applicant rejected');
    } catch (error) {
      console.error(error);
      toast.error('Failed to reject applicant');
    } finally {
      setLoading(false);
    }
  }

  const handleInviteInterview = async (interviewMessage) => {
    try {
      setLoading(true);
      await axiosInstance.post(`/job/applicant/invite/interview`, {
        applicantId: targetApplicant.id,
        jobId: id,
        interviewMessage,
      });
      await fetchCampaign();
      setOpenInviteInterviewDialog(false);
      toast.success('Interview invitation sent');
    } catch (error) {
      console.error(error);
      toast.error('Failed to send interview invitation');
    } finally {
      setLoading(false);
    }
  }

  const emailFormik = useFormik({
    initialValues: {
      subject: '',
      body: '',
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Email's subject is required"),
      body: Yup.string().required("Email's body is required"),
    }),
    onSubmit: async (values) => {
      try {
        setOverlayLoading(true);
        await sendCustomEmails(values.subject, values.body);
      } catch (error) {
        console.error(error);
      } finally {
        setOverlayLoading(false);
      }
    },
  });

  const [openConfirmOfferDialog, setOpenConfirmOfferDialog] = useState(false);
  const onClickOpenConfirmOfferDialog = (applicant) => {
    setTargetApplicant(applicant);
    setOpenConfirmOfferDialog(true);
  }

  const [openEmailInviteCreatorDialog, setOpenEmailInviteCreatorDialog] = useState(false);
  const onClickEmailInviteCreator = () => {
    setOpenInviteModal(false);
    setOpenEmailInviteCreatorDialog(true);
  }

  //check campaign logs events
  const [inviteEvents, setInviteEvents] = useState([]);
  useEffect(() => {
    if (job) {
      const events = job.logs.filter(log => log.name === 'creator-email-invite');
      setInviteEvents(events);
    }
  }, [job]);

  if (!job) return null;

  return (
    <>
      <Page title={job ? `Campaign | ${job.title}` : "loading..."} scrollingDisabled={false}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage={'ClientJobPage'} />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSideNav
            currentTab={'ClientJobPage'}
            userProfile={true}
            isAvatar={true}
            currentUser={currentUser}
            isProUser={true}
          />
          <LayoutWrapperMain className={css.wrapperMain}>
            <div className={css.content}>
              <div className={css.paddingContent}>
                <Breadcrumb
                  items={[
                    {
                      label: 'Jobs',
                      onClick: () => history.push('/job/client'),
                    },
                    {
                      label: `${job.title}`,
                    },
                  ]}
                />
              </div>
              <div className={css.paddingContent}>
                <div className={css.headerContainer}>
                  <div className={css.campaignNameText}>{job.title}</div>
                  <div className={css.headerActionContainer}>
                    {/* <div className={css.actionButtonContainer}>
                      <div
                        className={`${css.actionButtonMenu} ${
                          menuOpen ? `${css.visible}` : `${css.invisible}`
                        }`}
                      >
                        <div className={css.actionButtonMenuItem} onClick={() => history.push(`/campaigns/new-campaigns/${job.type}?edit=${job.id}`)}>Edit Campaign</div>
                        {job.status === PublicCampaignStatus.CLOSED ? (
                          <div className={css.actionButtonMenuItem} onClick={() => handleChangeCampaignStatus(PublicCampaignStatus.ACTIVE)}>Open Campaign</div>
                        ) : (
                          <div className={css.actionButtonMenuItem} onClick={() => handleChangeCampaignStatus(PublicCampaignStatus.CLOSED)}>Close Campaign</div>
                        )}
                      </div>
                      <div className={css.actionButton} onClick={handleToggleMenu}>
                        <img src={ellipsis_icon} alt="menu icon" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className={css.paddingContent}>
                <Switcher
                  activeKey={key}
                  setActiveKey={setKey}
                  onChange={() => {
                  }}
                  items={switcherItems}
                  fullWidth
                />
              </div>
              <>
                {loading ? (
                  <Loading width={"100%"} type='animation' />
                ) : (
                  <>
                    {key === 'overview' && (
                      <div className={css.overviewContainer}>
                        <JobDetailCard job={job} />
                        <div className={css.addMoreCreatorsContainer}>
                          <AddMoreCreators onClick={() => setOpenInviteModal(true)} />
                        </div>
                      </div>
                    )}
                    {key === 'applied' && (
                      <div className={css.paddingContent}>
                        <AppliedTable
                          applicants={applicants}
                          onClickOpenConfirmDialog={onClickOpenConfirmDialog}
                          onClickShortlist={onClickShortlist}
                        />
                      </div>
                    )}
                    {key === 'shortlist' && (
                      <div className={css.paddingContent}>
                        <ShortlistTable
                          applicants={shortlistApplicants}
                          onClickOpenConfirmDialog={onClickOpenConfirmDialog}
                          onClickOpenInterviewDialog={onClickOpenInterviewDialog}
                        />
                      </div>
                    )}
                    {key === 'interview' && (
                      <ChatContent
                        applicants={interviewApplicants}
                        onClickOpenConfirmDialog={onClickOpenConfirmDialog}
                        onClickOpenConfirmOfferDialog={onClickOpenConfirmOfferDialog}
                      />
                    )}
                    {key === 'offered' && (
                      <ChatContent
                        applicants={offeredApplicants}
                        onClickOpenConfirmDialog={onClickOpenConfirmDialog}
                        onClickOpenConfirmOfferDialog={onClickOpenConfirmOfferDialog}
                      />
                    )}
                    {key === 'rejected' && (
                      <div className={css.paddingContent}>
                        <RejectedTable
                          applicants={rejectedApplicants}
                          onClickOpenConfirmDialog={onClickOpenConfirmDialog}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          </LayoutWrapperMain>
        </LayoutSideNavigation>
      </Page>
      <ModalV2Simple
        isOpen={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
        title={"Invite Creators To Campaign"}
        width={800}
      >
        {inviteEvents.length > 0 && (
          <div className={css.inviteDisabledInfo}>
            You have already invited <b>all{" "}
            {inviteEvents.some((event) => event.type === 'male') && <b>men </b>}
            {inviteEvents.length > 1 && <b>and{" "}</b>}
            {inviteEvents.some((event) => event.type === 'female') && <b>women </b>}
            creators</b> in Singapore. Please allow at least 24 hours for creators to express their interest. You can view applicants in your <a href='?section=applied'>Applied</a> tab. If you have any questions, feel free to reach out via the blue chat icon at the bottom right corner or by emailing us at hello@collabreate.co
          </div>
        )}
        <div className={css.modalDescription}>Send invitation emails to creators.</div>
        <div className={css.inviteTypeContainer}>
          <div className={css.inviteTypeList}>
            <div className={css.emailInviteListLabel}>Creators who are on platform</div>
            <button onClick={() => history.push(`/job/client/detail/${id}/invite`)} className={css.inviteTypeItem}>
              Invite creators manually
            </button>
            <button
              className={`${css.inviteTypeItem} ${emailType === 'all' && css.inviteTypeItemActive}`}
              onClick={() => onClickEmailType('all')}
              disabled={inviteEvents.find((event) => ['male', 'all', 'female'].includes(event.type))}
            >
              Invite all creators in Singapore
            </button>
            <button
              className={`${css.inviteTypeItem} ${emailType === 'male' && css.inviteTypeItemActive}`}
              onClick={() => onClickEmailType('male')}
              disabled={inviteEvents.find((event) => ['male', 'all'].includes(event.type))}
            >
              Invite all creators in Singapore (Men)
            </button>
            <button
              className={`${css.inviteTypeItem} ${emailType === 'female' && css.inviteTypeItemActive}`}
              onClick={() => onClickEmailType('female')}
              disabled={inviteEvents.find((event) => ['female', 'all'].includes(event.type))}
            >
              Invite all creators in Singapore (Women)
            </button>
          </div>
          <div className={css.inviteTypeList}>
            <div className={css.emailInviteListLabel}>Creators who are off platform</div>
            <div className={`${css.inviteTypeItem}`} onClick={() => onClickEmailInviteCreator()}>
              Invite creator by Email
            </div>
            {/* <div className={`${css.inviteTypeItem}`} onClick={() => onClickCsvInvite()}>
              Bulk invite by CSV
            </div> */}
          </div>
        </div>
        {emailType && (
          <div className={css.emailForm}>
            <div>
              <div className={css.errorMessage}>{emailFormik.errors.subject}</div>
              <input name="subject" type="text" placeholder="Email's Subject" className={css.inputText} value={emailFormik.values.subject} onChange={emailFormik.handleChange} />
            </div>
            <div>
              <div className={css.errorMessage}>{emailFormik.errors.body}</div>
              <textarea name="body" className={css.inputTextarea} value={emailFormik.values.body} onChange={emailFormik.handleChange} placeholder="Email's Body" />
            </div>
            <div className={css.inviteButtonContainer}>
              <button className={css.inviteButton} onClick={emailFormik.handleSubmit} disabled={!emailFormik.dirty}>Send Invitation Email</button>
            </div>
          </div>
        )}
      </ModalV2Simple>
      <EmailJobInviteCreatorDialog
        open={openEmailInviteCreatorDialog}
        onClose={() => setOpenEmailInviteCreatorDialog(false)}
        selfEmail={currentUser?.attributes?.email}
        jobId={id}
      />
      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        title={`Confirm moving to the Shortlist?`}
        message={`Are you sure you want to move this creator to the Shortlist?`}
        onConfirm={handleShortlistApplicant}
      />
      <ConfirmationDialog
        open={openConfirmOfferDialog}
        onClose={() => setOpenConfirmOfferDialog(false)}
        title={`Confirm Job offering`}
        message={`Are you sure you want to offer this creator the job?`}
        onConfirm={handleOfferApplicant}
      />
      <RejectApplicantDialog
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        onConfirm={(message) => handleRejectApplicant(message)}
      />
      <InviteInterviewDialog
        open={openInviteInterviewDialog}
        onClose={() => setOpenInviteInterviewDialog(false)}
        onConfirm={(message) => handleInviteInterview(message)}
      />
      <LoadingOverlayScreen type={"animation"} isLoading={overlayLoading} />
    </>
  );
};

const RejectApplicantDialog = ({
  open,
  onClose,
  onConfirm,
}) => {
  const formik = useFormik({
    initialValues: {
      rejectMessage: `Hi Joie,

Thank you for taking the time to apply and interview with us for the [Job Title] position. After careful consideration, we regret to inform you that we have decided to move forward with another candidate.

We truly appreciate your interest in L'Oréal Paris and the effort you put into the process. We will keep your details on file for any future opportunities that match your skills and experience.

Wishing you all the best in your job search and future endeavors.

Best regards,
Emma
L'Oréal Paris`,
    }
  })

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      width={700}
      title={"Reject Candidate"}
    >
      <div className={css.rejectFormContainer}>
        <div>Feel free to adjust the message below as needed.</div>
        <TextAreaField value={formik.values.rejectMessage} onChange={formik.handleChange} name={'rejectMessage'} height={'400px'} />
        <Button fullWidth onClick={() => onConfirm(formik.values.rejectMessage)}>Reject</Button>
      </div>
    </ModalV2Simple>
  )
}

const InviteInterviewDialog = ({
  open,
  onClose,
  onConfirm,
}) => {
  const formik = useFormik({
    initialValues: {
      interviewMessage: `Hi Joie,

We are pleased to invite you to an interview for the UGC Content Creator position. We look forward to learning more about you and discussing how you could be a great fit for our team.

Date: [Insert Date]
Time: [Insert Time]
Location/Link: [Office Address or Video Call Link]

Please confirm your availability or suggest an alternative time if needed. We look forward to speaking with you!

Best regards,
Emma
L'Oréal Paris`,
    }
  })

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      width={700}
      title={"Invite to interview"}
    >
      <div className={css.rejectFormContainer}>
        <div>Feel free to adjust the message below as needed.</div>
        <TextAreaField value={formik.values.interviewMessage} onChange={formik.handleChange} name={'interviewMessage'} height={'400px'} />
        <Button fullWidth onClick={() => onConfirm(formik.values.interviewMessage)}>Invite</Button>
      </div>
    </ModalV2Simple>
  )
}

const mapStateToProps = state => {
  const { currentUser, currentUserHasListings } = state.user;
  const { isAuthenticated } = state.Auth;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    isAuthenticated,
    getListing,
  };
};

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DetailJobPage);
