import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import css from './ChatPage.module.css';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axiosInstance from '../../axios';
import paperClipIcon from '../../assets/icons/paper-clip.svg';
import paperAirplane from '../../assets/icons/paper-airplane.svg';
import { useSocket } from '../../hooks/useSocket';
import { formatChatCreatedDate } from '../../util/dates';
import { Button } from '../../components/New/Button/Button';
import { isEmpty } from 'lodash';

const ChatPage = ({ currentUser }) => {
  const { id } = useParams();
  const userId = currentUser?.id?.uuid;

  const socket = useSocket();
  const [chatroom, setChatroom] = useState(null);
  const [user, setUser] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [height, setHeight] = useState(40);

  const fetchChatroom = async () => {
    const response = await axiosInstance.get(`/chatroom/detail/${id}`);
    const { data } = response.data;
    setChatroom(data);
    setChatMessages(data.messages);
  }
  const fetchUser = async () => {
    const response = await axiosInstance.get(`/user/detail/${userId}`);
    await axiosInstance.post(`/chatroom/read/all/${id}`, {
      userId,
    });
    const { data } = response.data;
    setUser(data);
  }
  useEffect(() => {
    if (id) {
      Promise.allSettled([
        fetchChatroom(),
        fetchUser(),
      ])
    }

    if (socket) {
      socket.on('fetchUnreadMessages', (msg) => {
        fetchChatroom();
      });
    }

    return () => {
      socket.off('fetchUnreadMessages');
    };
  }, [id, socket]);

  const handleInputChange = (e) => {
    setHeight(isEmpty(e.target.value) ? 40 : e.target.scrollHeight);
    setNewMessage(e.target.value);
  }

  const sendNewMessage = async () => {
    try {
      if (isEmpty(newMessage)) {
        return;
      }

      setChatMessages([...chatMessages, {
        message: newMessage,
        role: user.userType === 'creator' ? 'provider' : 'customer',
        userId: user.id,
        createdAt: new Date(),
      }]);
      setNewMessage('');

      // new logic
      await axiosInstance.post('/messages/create', {
        data: {
          chatroomId: parseInt(id),
          role: user.userType === 'creator' ? 'provider' : 'customer',
          userId: user.id,
          message: newMessage,
        }
      });

      socket.emit('newMessage', newMessage);
    } catch (error) {
      console.error(error);
    }
  }

  const messageRole = user?.userType === 'creator' ? 'provider' : 'customer';

  return (
    <Page
      title={"Job Page"}
      scrollingDisabled={false}
    >
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={'CreatorJobPage'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab={'CreatorJobPage'}
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.root}>
            <div className={css.right}>
              {chatroom && user && (
                <div className={css.chat}>
                  <div className={css.chatBanner}>
                    <div className={css.chatBannerContent}>
                      <img src={user.userType === 'creator' ? chatroom.customer.avatar : chatroom.provider.avatar} alt="Creator" className={css.avatar} />
                      <div className={css.bannerName}>{user.userType === 'creator' ? chatroom.customer.displayName : chatroom.provider.displayName}</div>
                    </div>
                  </div>
                  <div className={css.chatContainer}>
                    {chatMessages.map((message) => (
                      <div className={message.role !== messageRole ? css.bubbleLeft : css.bubbleRight}>
                        <div className={css.bubble}>
                          <div className={css.bubbleCreatedAt}>{formatChatCreatedDate(message.createdAt)}</div>
                          {message.message}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={css.chatInputContainer}>
                    <button className={css.attachButton}><img src={paperClipIcon} className={css.chatButtonIcon}/></button>
                    <textarea placeholder='Message' value={newMessage} className={css.messageInput} onChange={handleInputChange} style={{ height: `${height}px`, maxHeight: '100px' }} />
                    <button className={css.sendButton} onClick={sendNewMessage}><img src={paperAirplane} className={css.chatButtonIcon}/></button>
                  </div>
                </div>
              )}
            </div>
            <div className={css.banner}>
              {chatroom && user && (
                <div className={css.bannerContainer}>
                  <img src={user.userType === 'creator' ? chatroom.customer.avatar : chatroom.provider.avatar} className={css.providerAvatar} />
                  <div className={css.providerName}>{user.userType === 'creator' ? chatroom.customer.displayName : chatroom.provider.displayName}</div>
                  <div>{user.userType === 'creator' ? chatroom.customer.userType : chatroom.provider.activity}</div>
                  <div className={css.buttonContainer}>
                    {chatroom.type === 'job' && user.userType === 'creator' && (
                      <CreatorJobButtonBanner chatroom={chatroom} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  )
}

const CreatorJobButtonBanner = ({
  chatroom,
}) => {
  const history = useHistory();

  const [job, setJob] = useState(null);

  const fetchJob = async () => {
    const response = await axiosInstance.get(`/job/detail/${chatroom.transactionId}`);
    const { data } = response.data;
    setJob(data);
  }
  useEffect(() => {
    fetchJob();
  }, [chatroom.transactionId]);

  if (!job) {
    return null;
  }

  return (
    <div className={css.jobButtonContainer}>
      <div>
        <div className={css.jobTitle}>{job.title}</div>
        <div className={css.subtitle}>Job Application</div>
      </div>
      <Button color='gray' onClick={() => history.push('/job/creator/apply/' + job.id)}>View job</Button>
    </div>
  )
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
};

export default compose(connect(mapStateToProps))(ChatPage);
