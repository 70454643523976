import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import { NotFoundPage, PublicCampaignCreatorDetailPage } from '../containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AuthenticationPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'
  )
);
const MainPage = loadable(() =>
  import(/* webpackChunkName: "MainPage" */ '../containers/MainPage/MainPage')
);
const PricingPage = loadable(() =>
  import(/* webpackChunkName: "PricingPage" */ '../containers/PricingPage/PricingPage')
);
const ReferralPage = loadable(() =>
  import(/* webpackChunkName: "ReferralPage" */ '../containers/ReferralPage/ReferralPage')
);
const CheckoutPage = loadable(() =>
  import(/* webpackChunkName: "CheckoutPage" */ '../containers/NewCheckoutPage/CheckoutPage')
);
const ListingPage = loadable(() =>
  import(/* webpackChunkName: "ListingPage" */ '../containers/ListingPage/ListingPage')
);
const BecomeProPage = loadable(() =>
  import(/* webpackChunkName: "BecomeProPage" */ '../containers/BecomeProPage/BecomeProPage')
);
const InboxPage = loadable(() =>
  import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage')
);
const SearchPage = loadable(() =>
  import(
    /* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */ '../containers/SearchPage/SearchPage'
  )
);
const CategoriesPage = loadable(() =>
  import(
    /* webpackChunkName: "CategoriesPage" */ /* webpackPrefetch: true */ '../containers/CategoriesPage/CategoriesPage'
  )
);
const MyCalendarPage = loadable(() =>
  import(
    /* webpackChunkName: "MyCalendarPage" */ /* webpackPrefetch: true */ '../containers/MyCalendarPage/MyCalendarPage'
  )
);
const WalletPage = loadable(() =>
  import(
    /* webpackChunkName: "WalletPage" */ /* webpackPrefetch: true */ '../containers/WalletPage/WalletPage'
  )
);
const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'
  )
);
const ProfilePage = loadable(() =>
  import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage')
);
const EditListingPage = loadable(() =>
  import(
    /* webpackChunkName: "EditListingPage" */ '../containers/NewEditListingPage/EditListingPage'
  )
);
const TransactionPage = loadable(() =>
  import(
    /* webpackChunkName: "TransactionPage" */ '../containers/NewTransactionPage/TransactionPage'
  )
);
const BookDemoPage = loadable(() =>
  import(/* webpackChunkName: "BookDemoPage" */ '../containers/BookDemoPage/BookDemoPage')
);
const BookDemoSuccessPage = loadable(() =>
  import(
    /* webpackChunkName: "BookDemoSuccessPage" */ '../containers/BookDemoSuccessPage/BookDemoSuccessPage'
  )
);
const WaitlistPage = loadable(() =>
  import(/* webpackChunkName: "WaitlistPage" */ '../containers/WaitlistPage/WaitlistPage')
);
const SuccessSubscriptionPage = loadable(() =>
  import(
    /* webpackChunkName: "SuccessSubscriptionPage" */ '../containers/SuccessSubscriptionPage/SuccessSubscriptionPage'
  )
);
const PartnerDealsPage = loadable(() =>
  import(
    /* webpackChunkName: "PartnerDealsPage" */ '../containers/PartnerDealsPage/PartnerDealsPage'
  )
);
const PartnerDealsDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "PartnerDealsDetailsPage" */ '../containers/PartnerDealsPage/Client/DealsDetails/ClientDealsDetails'
  )
);
const CreatorServicesPage = loadable(() =>
  import(
    /* webpackChunkName: "CreatorServicesPage" */ '../containers/CreatorServicesPage/CreatorServicesPage'
  )
);
const PartnerDealsCreateNewDealPage = loadable(() =>
  import(
    /* webpackChunkName: "PartnerDealsCreateNewDealPage" */ '../containers/PartnerDealsPage/Client/CreateNewDeal/CreateNewDeal'
  )
);
const PartnerDealsEditDealPage = loadable(() =>
  import(
    /* webpackChunkName: "PartnerDealsEditDealPage" */ '../containers/PartnerDealsPage/Client/EditDeal/EditDeal'
  )
);
const InternalPartnerDealsPage = loadable(() =>
  import(
    /* webpackChunkName: "PartnerDealsCreateNewDealPage" */ '../containers/Internal/InternalPartnerDealsPage/InternalPartnerDealsPage'
  )
);
const CaseStudyPage = loadable(() =>
  import(/* webpackChunkName: "CaseStudyPage" */ '../containers/CaseStudyPage/CaseStudyPage')
);
const CaseStudyCreationEditPage = loadable(() =>
  import(
    /* webpackChunkName: "CaseStudyCreationEditPage" */ '../containers/CaseStudyCreationEditPage/CaseStudyCreationEditPage'
  )
);
const NewCampaignPage = loadable(() =>
  import(/* webpackChunkName: "NewCampaignPage" */ '../containers/Campaigns')
);
const NewTargetedCampaignPage = loadable(() =>
  import(
    /* webpackChunkName: "NewTargetedCampaignPage" */ '../containers/Campaigns/CreateNewCampaign/CreateNewCampaign'
  )
);
const CampaignContractingPage = loadable(() =>
  import(
    /* webpackChunkName: "CampaignContractingPage" */ '../containers/CampaignContractingPage/TransactionPage'
  )
);
const CampaignCompletedPage = loadable(() =>
  import(
    /* webpackChunkName: "CampaignContractingPage" */ '../containers/CampaignCompletedPage/TransactionPage'
  )
);
const CampaignCheckoutPage = loadable(() =>
  import(
    /* webpackChunkName: "CampaignCheckoutPage" */ '../containers/CampaignCheckoutPage/CheckoutPage'
  )
);
const PublicCampaignClientDetailPage = loadable(() =>
  import(
    /* webpackChunkName: "PublicCampaignClientDetailPage" */ '../containers/PublicCampaignClientDetailPage/PublicCampaignClientDetailPage'
  )
);
const AddCreatorsToCampaignPage = loadable(() =>
  import(
    /* webpackChunkName: "AddCreatorsToCampaignPage" */ '../containers/AddCreatorsToCampaignPage/AddCreatorsToCampaignPage'
  )
);
const ListingMarketplace = loadable(() =>
  import(
    '../containers/ListingMarketplace/CategoriesPage'
  )
)
// const PublicCampaignCreatorDetailPage = loadable(() =>
//   import(
//     '../containers/PublicCampaignCreatorDetailPage/PublicCampaignCreatorDetailPage'
//   )
// );
const ClientPublicCampaignListPage = loadable(() =>
  import(
    '../containers/ClientPublicCampaignList/ClientPublicCampaignListPage'
  )
);
const PublicCampaignClientPerformancePage = loadable(() =>
  import(
    '../containers/PublicCampaignClientPerformancePage/PublicCampaignClientPerformancePage'
  )
);

const CreatorOnBoardingPage = loadable(() =>
  import(
    '../containers/OnBoardingPage/Creator/CreatorOnBoarding'
  )
);
const ClientAmbassadorPage = loadable(() =>
  import(
    '../containers/Ambassador/ClientAmbassadorPage/ClientAmbassadorPage'
  )
);
const CreateAmbassadorPage = loadable(() =>
  import(
    '../containers/Ambassador/CreateAmbassadorPage/CreateAmbassadorPage'
  )
);
const EditAmbassadorPage = loadable(() =>
  import(
    '../containers/Ambassador/EditAmbassadorPage/EditAmbassadorPage'
  )
);

import {
  AccessibilityPage,
  ContactDetailsPage,
  EmailVerificationPage,
  HelpGeneralPage,
  HelpClientPage,
  HelpTrainerPage,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  StripePayoutPage,
  PaymentMethodsPage,
  PrivacyPolicyPage,
  ProfileSettingsPage,
  StyleguidePage,
  TermsOfServicePage,
  SessionsPage,
  EnquiresPage,
  ReviewsPage,
} from '../containers';
import CaseStudyCreationPage from '../containers/CaseStudyCreationPage/CaseStudyCreationPage';
import CaseStudyCreationNewPage from '../containers/CaseStudyCreationNewPage/CaseStudyCreationNewPage';
import PublicCampaignClientCheckoutPage from '../containers/PublicCampaignClientCheckoutPage/PublicCampaignClientCheckoutPage';
import ApplyAmbassadorPage from '../containers/Ambassador/ApplyAmbassadorPage/ApplyAmbassadorPage';
import { ViewShortlistPage } from '../containers/Campaign/Shortlist/ViewShortlistPage/ViewShortlistPage';
import { loadData } from '../containers/AuthenticationPage/AuthenticationPage.duck';
import CreatorViewJobPage from '../containers/Job/Creator/CreatorViewJobPage/CreatorViewJobPage';
import ClientViewJobPage from '../containers/Job/Client/ClientViewJobPage/ClientViewJobPage';
import EditJobPage from '../containers/Job/Client/EditJobPage/EditJobPage';
import ApplyJobPage from '../containers/Job/Creator/ApplyPage/ApplyJobPage';
import DetailJobPage from '../containers/Job/Client/DetailJobPage/DetailJobPage';
import ChatPage from '../containers/ChatPage/ChatPage';
import AddCreatorsToJobPage from '../containers/AddCreatorsToJobPage/AddCreatorsToJobPage';

export const ACCOUNT_SETTINGS_PAGES = [
  'ProfileSettingsPage',
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
//

const routeConfiguration = () => {
  return [
    {
      path: '/pricing',
      name: 'PricingPage',
      component: PricingPage,
    },
    {
      path: '/',
      name: 'BecomeProPage',
      component: BecomeProPage,
    },
    {
      path: '/brands',
      name: 'LandingPage',
      component: MainPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/case-study',
      name: 'CaseStudyPage',
      component: CaseStudyPage,
    },
    {
      path: '/case-study/creation',
      name: 'CaseStudyCreationPage',
      component: CaseStudyCreationPage,
    },
    {
      path: '/case-study/creation/new',
      name: 'CaseStudyCreationNewPage',
      component: CaseStudyCreationNewPage,
    },
    {
      path: '/case-study/creation/edit/:id',
      name: 'CaseStudyCreationNewPage',
      component: CaseStudyCreationEditPage,
    },
    {
      path: '/accessibility',
      name: 'AccessibilityPage',
      component: props => <AccessibilityPage {...props} />,
    },
    {
      path: '/help-center',
      name: 'HelpGeneralPage',
      component: props => <HelpGeneralPage {...props} />,
    },
    {
      path: '/help-center-client',
      name: 'HelpClientPage',
      component: props => <HelpClientPage {...props} />,
    },
    {
      path: '/help-center-trainer',
      name: 'HelpTrainerPage',
      component: props => <HelpTrainerPage {...props} />,
    },
    {
      path: '/s',
      name: 'SearchPage',
      paidAccess: true,
      component: SearchPage,
      auth: true,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/listings',
      name: 'CategoriesPage',
      paidAccess: true,
      auth: true,
      component: CategoriesPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/client/ambassador',
      name: 'ClientAmbassadorPage',
      auth: true,
      component: ClientAmbassadorPage,
    },
    {
      path: '/client/ambassador/detail/:id',
      name: 'ClientAmbassadorPage',
      auth: true,
      component: EditAmbassadorPage,
    },
    {
      path: '/client/ambassador/create',
      name: 'CreateAmbassadorPage',
      auth: true,
      component: CreateAmbassadorPage,
    },
    {
      path: '/ambassador/*',
      name: 'ApplyAmbassadorPage',
      component: props => <ApplyAmbassadorPage {...props} />,
    },
    {
      path: '/campaign/shared/shortlist/:id',
      name: 'CampaignSharedShortlistPage',
      component: props => <ViewShortlistPage {...props} />,
    },
    {
      path: '/job/client',
      name: 'ClientJobPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ClientViewJobPage {...props} />,
    },
    {
      path: '/job/client/edit/:id',
      name: 'ClientJobPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EditJobPage {...props} />,
    },
    {
      path: '/job/client/detail/:id',
      name: 'ClientJobPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <DetailJobPage {...props} />,
    },
    {
      path: '/job/client/detail/:id/invite',
      name: 'ClientJobPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AddCreatorsToJobPage {...props} />,
    },
    {
      path: '/job/creator',
      name: 'CreatorJobPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <CreatorViewJobPage {...props} />,
    },
    {
      path: '/job/creator/apply/:id',
      name: 'CreatorJobPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ApplyJobPage {...props} />,
    },
    {
      path: '/chat/:id',
      name: 'ChatPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ChatPage {...props} />,
    },
    // {
    //   path: '/public-campaign/detail/:id/invite',
    //   name: 'ListingMarketplace',
    //   paidAccess: true,
    //   auth: true,
    //   component: ListingMarketplace,
    //   loadData: pageDataLoadingAPI.SearchPage.loadData,
    // },

    // {
    //   path: '/s/filters',
    //   name: 'SearchFiltersPage',
    //   component: props => <SearchPage {...props} tab="filters" />,
    //   loadData: pageDataLoadingAPI.SearchPage.loadData,
    // },
    // {
    //   path: '/s/listings',
    //   name: 'SearchListingsPage',
    //   component: props => <SearchPage {...props} tab="listings" />,
    //   loadData: pageDataLoadingAPI.SearchPage.loadData,
    // },
    // {
    //   path: '/s/map',
    //   name: 'SearchMapPage',
    //   component: props => <SearchPage {...props} tab="map" />,
    //   loadData: SearchPage.loadData,
    // },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },

    // {
    //   path: '/l/:slug/:id',
    //   name: 'ListingPage',
    //   component: props => <ListingPage {...props} />,
    //   loadData: ListingPage.loadData,
    // },

    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      paidAccess: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      paidAccess: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      paidAccess: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      paidAccess: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      auth: true,
      paidAccess: true,
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      auth: true,
      component: RedirectToLandingPage,
    },
    {
      path: '/u/upcoming-sessions',
      name: 'UpcomingSessionsPage',
      auth: true,
      paidAccess: true,
      authPage: 'LoginPage',
      component: props => <SessionsPage {...props} isUpcomingSessions={true} />,
      loadData: SessionsPage.loadData,
      //
    },

    {
      path: '/u/past-sessions',
      name: 'PastSessionsPage',
      auth: true,
      paidAccess: true,
      authPage: 'LoginPage',
      component: props => <SessionsPage {...props} isUpcomingSessions={false} />,
      loadData: SessionsPage.loadData,
      //
    },

    // {
    //   path: '/u',
    //   name: 'ProfileBasePage',
    //   // component: props => <ProfilePage {...props} />,
    //   // loadData: ProfilePage.loadData,
    //   component: props => (
    //     <NamedRedirect name="ProfilePage" {...props} params={{ tab: 'orders' }} />
    //   ),
    //   // params={{ tab: 'sales' }}
    //   ///
    // },
    {
      path: '/u/enquiries',
      name: 'EnquiriesPage',
      auth: true,
      paidAccess: true,
      authPage: 'LoginPage',
      component: props => (
        <EnquiresPage {...props} isProSessions={false} isUpcomingSessions={false} />
      ),
      loadData: params => EnquiresPage.loadData({ ...params, isProSessions: false }),
    },
    {
      path: '/creator/upcoming-sessions',
      name: 'ProUpcomingSessionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <SessionsPage {...props} isUpcomingSessions={true} isProSessions={true} />
      ),
      loadData: params => SessionsPage.loadData({ ...params, isProSessions: true }),
    },
    {
      path: '/creator/past-sessions',
      name: 'ProPastSessionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <SessionsPage {...props} isProSessions={true} isUpcomingSessions={false} />
      ),
      loadData: params => SessionsPage.loadData({ ...params, isProSessions: true }),
    },
    {
      path: '/creator/enquiries',
      name: 'EnquiriesProPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <EnquiresPage {...props} isProSessions={true} isUpcomingSessions={false} />
      ),
      loadData: params => EnquiresPage.loadData({ ...params, isProSessions: true }),
    },
    {
      path: '/creator/my-calendar',
      name: 'MyCalendarPage',
      auth: true,
      component: MyCalendarPage,
      loadData: pageDataLoadingAPI.MyCalendarPage.loadData,
    },
    {
      path: '/creator/services',
      name: 'CreatorServicesPage',
      auth: true,
      authPage: 'LoginPage',
      component: CreatorServicesPage,
    },
    {
      path: '/creator/wallet',
      name: 'WalletPage',
      auth: true,
      paidAccess: true,
      component: WalletPage,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/creator/my-listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/creator/reviews',
      name: 'ReviewsPage',
      auth: true,
      component: props => <ReviewsPage {...props} isProUser={true} isProSessions={true} />,
      //loadData: ReviewsPage.loadData,
    },
    {
      path: '/creator/:id/listings',
      name: 'CreatorListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.CreatorListingsPage.loadData,
    },
    {
      path: '/creator/:id/reviews',
      name: 'CreatorReviewsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/creator/:id/analytics',
      name: 'CreatorAnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/creator/:id/resume',
      name: 'CreatorResumePage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/creator/:id/internal-notes',
      name: 'CreatorInternalNotesPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/u/:id/listings',
      name: 'CreatorListingsPagePublic',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/u/:id/reviews',
      name: 'CreatorReviewsPagePublic',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/u/:id/analytics',
      name: 'CreatorAnalyticsPagePublic',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/u/:id/resume',
      name: 'CreatorResumePagePublic',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/u/:id/internal-notes',
      name: 'CreatorInternalNotesPagePublic',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/creator/:slug/:id',
      name: 'ListingPage',
      auth: true,
      paidAccess: true,
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/callback',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      // loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/creator/:id',
      name: 'ProProfilePage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      paidAccess: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => <InboxPage {...props} />,
      loadData: pageDataLoadingAPI.loadData,
    },
    {
      path: '/book-a-demo/:role',
      name: 'BookDemoPage',
      component: BookDemoPage,
    },
    {
      path: '/book-a-demo-success',
      name: 'BookDemoSuccessPage',
      component: BookDemoSuccessPage,
    },
    {
      path: '/subscription/:id',
      name: 'SuccessSubscriptionPage',
      component: SuccessSubscriptionPage,
    },

    {
      path: '/waitlist',
      name: 'WaitlistPage',
      component: WaitlistPage,
    },
    // {
    //   path: '/order/:id',
    //   name: 'OrderPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    // },
    // {
    //   path: '/order/:id/details',
    //   name: 'OrderDetailsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <TransactionPage {...props} transactionRole="customer" />,
    //   loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
    //   setInitialValues: TransactionPage.setInitialValues,
    // },
    // {
    //   path: '/sale/:id',
    //   name: 'SalePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    // },
    // {
    //   path: '/sale/:id/details',
    //   name: 'SaleDetailsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <TransactionPage {...props} transactionRole="provider" />,
    //   loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    // },
    {
      path: '/order/:id',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData(
          { ...params, transactionRole: 'customer' },
          ...rest
        ),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => (
        <NamedRedirect name="OrderDetailsPage" params={{ id: props.params?.id }} />
      ),
    },
    {
      path: '/sale/:id',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => (
        <NamedRedirect name="SaleDetailsPage" params={{ id: props.params?.id }} />
      ),
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} />,
      loadData: ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PasswordChangePage {...props} />,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/terms-of-use-creators',
      name: 'CreatorsTermsOfServicePage',
      component: props => <TermsOfServicePage {...props} creators={true} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
    {
      path: '/referral',
      name: 'ReferralPage',
      auth: true,
      authPage: 'LoginPage',
      // component: props => <ReferralPage {...props} />,
      component: ReferralPage,
      // loadData: ReferralPage.loadData,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    // {
    //   path: '/preview',
    //   name: 'PreviewResolverPage',
    //   component: PreviewResolverPage ,
    // },
    {
      path: '/onboarding/creator',
      name: 'CreatorOnBoardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: CreatorOnBoardingPage,
    },
    {
      path: '/campaign/apply/:campaignId',
      name: 'PublicCampaignCreatorDetailPage',
      // component: PublicCampaignCreatorDetailPage,
      component: props => <PublicCampaignCreatorDetailPage {...props} />,
    },
    {
      path: '/campaign/detail/:campaignId',
      name: 'PublicCampaignClientDetailPage',
      auth: true,
      authPage: 'LoginPage',
      component: PublicCampaignClientDetailPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/campaign/detail/:campaignId/checkout',
      name: 'PublicCampaignClientCheckoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PublicCampaignClientCheckoutPage {...props} />,
    },
    {
      path: '/campaign/detail/:campaignId/performance',
      name: 'PublicCampaignClientPerformancePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PublicCampaignClientPerformancePage {...props} />,
    },
    {
      path: '/campaign/list/:clientId',
      name: 'PublicCampaignClientListPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ClientPublicCampaignListPage {...props} />,
    },

    {
      path: '/campaigns',
      name: 'PartnerDealsPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => <PartnerDealsPage {...props} />,
      loadData: pageDataLoadingAPI.PartnerDealsPage.loadData,
    },
    {
      path: '/campaigns/new-campaign',
      name: 'NewCampaignPage',
      auth: true,
      paidAccess: true,
      authPage: 'LoginPage',
      component: props => <NewCampaignPage {...props} />,
      loadData: pageDataLoadingAPI.CampaignsPage.loadData,
    },
    {
      path: '/campaigns/new-campaign/:type',
      name: 'PartnerDealsCreateNewDealPage',
      auth: true,
      paidAccess: true,
      authPage: 'LoginPage',
      component: props => <PartnerDealsCreateNewDealPage {...props} />,
    },
    {
      path: '/campaigns/new-campaigns/:type',
      name: 'NewTargetedCampaignPage',
      auth: true,
      paidAccess: true,
      authPage: 'LoginPage',
      component: props => <NewTargetedCampaignPage {...props} />,
    },
    {
      path: '/campaigns/edit-campaign/:dealId',
      name: 'PartnerDealsEditDealPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => <PartnerDealsEditDealPage {...props} />,
    },
    {
      path: '/campaigns/:dealId',
      name: 'PartnerDealsDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => <PartnerDealsDetailsPage {...props} />,
      loadData: pageDataLoadingAPI.PartnerDealsDetailsPage.loadData,
    },
    {
      path: '/internal/campaigns',
      name: 'InternalPartnerDealsPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => <InternalPartnerDealsPage {...props} />,
      loadData: pageDataLoadingAPI.PartnerDealsDetailsPage.loadData,
    },
    {
      path: '/campaign/:campaignId/contracting/:id',
      name: 'CampaignContractingPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: CampaignContractingPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData(
          { ...params, transactionRole: 'customer' },
          ...rest
        ),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/campaign/:campaignId/completed/:id',
      name: 'CampaignCompletedPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: CampaignCompletedPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData(
          { ...params, transactionRole: 'customer' },
          ...rest
        ),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/campaign/:campaignId/:slug/:id/checkout/:applicantId',
      name: 'CampaignCheckoutPage',
      auth: true,
      paidAccess: true,
      component: CampaignCheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/campaign/detail/:id/invite',
      name: 'AddCreatorsToCampaignPage',
      auth: true,
      authPage: 'LoginPage',
      paidAccess: true,
      component: props => <AddCreatorsToCampaignPage {...props} />,
    },
  ];
};

export default routeConfiguration;
