import css from './JobDetailCard.module.css';
import { camelToLabel } from '../../../../../../util/string';
import { Chip } from '../../../../../../components/NewChip/Chip';
import { formatStringDate, getDaysAfter } from '../../../../../../util/dates';

export const JobDetailCard = ({ job }) => {
  let statusColor = 'success';

  return (
    <div className={css.container}>
      <div className={css.contentContainer}>
        <div>
          <div className={css.headerContainer}>
            <div className={css.name}>{job.title}</div>
            <Chip color={statusColor}>{camelToLabel(job.status)}</Chip>
          </div>
          <div className={css.headerContainer}>
            <div>{job.location}</div>
            <div className={css.subhead}>Posted {formatStringDate(job.createdAt)}</div>
          </div>
        </div>
        <div className={css.basicInfoContainer}>
          <div className={css.basicInfo}>
            <div className={css.basicInfoLabel}>Offer type</div>
            <div className={css.basicInfoValue}>{camelToLabel(job.type)}</div>
          </div>
          <div className={css.basicInfo}>
            <div className={css.basicInfoLabel}>Application deadline</div>
            <div className={css.basicInfoValue}>{formatStringDate(job.applicationEndDate)}</div>
          </div>
          <div className={css.basicInfo}>
            <div className={css.basicInfoLabel}>Contract duration</div>
            <div className={css.basicInfoValue}>{job.duration} {camelToLabel(job.durationType)}</div>
          </div>
        </div>
        <div>
          <div className={css.descriptionLabel}>Description</div>
          <div className={css.descriptionValue}>{job.description}</div>
        </div>
      </div>
    </div>
  )
}

function daysBetween(targetDate) {
  // Get the difference in milliseconds
  const target = new Date(targetDate);
  const differenceInMilliseconds = new Date() - target;

  // Convert milliseconds to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  return Math.ceil(differenceInDays);
}
